import { ThemeProvider } from "@mui/material";
import { ConfigProvider } from "antd";
import React, { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
import WidgetLoader from "./components/Loaders/WidgetLoader";
import PrivateRoute from "./components/privateRoute";
import { SnackbarProvider } from "./components/snackbar/SnackbarContext";
import Importers from "./pages/CBAM/Importers";
import Invoice from "./pages/CBAM/Invoice";
import { Muitheme } from "./pages/Mui/Themes";
import ActionCatalogue from "./pages/Plan/Action/NewActionPlan/NewActionMeasure/ActionCatalogue/ActionCatalogue";
import NewActionMeasure from "./pages/Plan/Action/NewActionPlan/NewActionMeasure/NewActionMeasure";
import NewActionPlan from "./pages/Plan/Action/NewActionPlan/NewActionPlan";
import SingleUpload from "./pages/SingleUpload/SingleUpload";
import BreakdownDetails from "./pages/CBAM/BreakdownDetails";
import Checklist from "./pages/UserManagement/UserList/Checklist/Checklist";
import { useLazyGetUserDetailByIdQuery } from "./state/api";
import { useSelector } from "react-redux";
import EsgUpload from "./pages/EsgUpload/EsgUpload";
import AuditLog from "./pages/AuditLog/AuditLog";
import { DataQuality } from "./pages/Measure/DataQuality/DataQuality";
import AddContributors from "./pages/BRSRNew/AddContributors/AddContributors";
import BreakDown from "./pages/Measure/DataQuality/Breakdown/BreakDown";
import MyReportList from "./pages/BRSRNew/MyReportList/MyReportList";
import Layout from "./components/Layout/Layout";
import PrincipleFour from "./pages/BRSRNew/Steps/SectionC/PrincipleFour/PrincipleFour";
import GlobalAssignQuestionsBulk from "./pages/BRSRNew/GlobalAssignQuestionsBulk/GlobalAssignQuestionsBulk";

const ReportPdfPreview = lazy(() => import("./pages/BRSRNew/ReportPdfPreview/ReportPdfPreview"));
const LandingPage = lazy(() => import("./pages/PeerAnalysis/LandingPage"));
const DataRequest = lazy(() => import("./pages/PeerAnalysis/DataRequest/DataRequest"));
const SelectPeer = lazy(() => import("./pages/PeerAnalysis/SelectPeer/SelectPeer"));
const CreatePeer = lazy(() => import("./pages/PeerAnalysis/CreatePeer"));
const MultipleAnswers = lazy(() => import("./pages/BRSRNew/ApprovalSteps/MultipleAnswers/MultipleAnswers"));
const ApproveQuestions = lazy(() => import("./pages/BRSRNew/ApproveQuestions"));
const AssignedQuestion = lazy(() => import("./pages/BRSRNew/AssignQuestions/AssignedQuestion"));
const BulkAssignQuestion = lazy(() => import("./pages/BRSRNew/BulkAssignQuestions/BulkAssignQuestion"));
const BRSRDashboard = lazy(() => import("./pages/BRSRNew/AdminDashboard/BRSRDashboard"));
const AssignQuestion = lazy(() => import("./pages/BRSRNew/AssignQuestions/AssignQuestion"));
const CreateReport = lazy(() => import("./pages/BRSRNew/CreateReport/CreateReport"));
const Allreports = lazy(() => import("./pages/BRSRNew/Allreports"));
const BRSRnew = lazy(() => import("./pages/BRSRNew/BRSRnew"));
const BRSRLayout = lazy(() => import("./components/BRSRComponets/BRSRLayout/BRSRLayout"));
const Home = lazy(() => import("./pages/Home/Home"));
const Employee = lazy(() => import("./pages/Employee/Employee"));
const Supplier = lazy(() => import("./pages/Supplier/Supplier"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const Organization = lazy(() => import("./pages/Organization/Organization"));
const Userlist = lazy(() => import("./pages/UserManagement/UserList/Userlist"));
const Permissions = lazy(() => import("./pages/UserManagement/UserList/Permissions"));
const Measure = lazy(() => import("./pages/Measure/Measure"));
const Login = lazy(() => import("./pages/Home/Login/Login"));
const Register = lazy(() => import("./pages/Home/Register/Register"));
const AirEmissions = lazy(() => import("./pages/Measure/AirEmissions/AirEmissions"));
const ResetPassword = lazy(() => import("./pages/Home/Reset/ResetPassword"));
const ForgotPassword = lazy(() => import("./pages/Home/Forgot/Forgot"));
const Success = lazy(() => import("./pages/Home/Success/Success"));
const Form = lazy(() => import("./pages/Reports/Form/Form"));
const Analysis = lazy(() => import("./pages/Analysis/Analysis"));
const Offset = lazy(() => import("./pages/Offset/Offset"));
const Klean = lazy(() => import("./pages/Klean/Klean"));
const Solution = lazy(() => import("./pages/Klean/Solution/Solution"));
const Action = lazy(() => import("./pages/Plan/Action/Action"));
const Targets = lazy(() => import("./pages/Plan/Targets/Targets"));
const Simulations = lazy(() => import("./pages/Plan/Simulations/Simulations"));
const Subcategory = lazy(() => import("./pages/Plan/Simulations/Subcategory/Subcategory"));
const Measures = lazy(() => import("./pages/Plan/Simulations/Subcategory/Measures"));
const Products = lazy(() => import("./pages/CBAM/Product"));
const Waste = lazy(() => import("./pages/Measure/Waste/Waste"));
const Water = lazy(() => import("./pages/Measure/Water/Water"));
const Energy = lazy(() => import("./pages/Measure/Energy/Energy"));
const Social = lazy(() => import("./pages/Measure/Social/Social"));
const Governance = lazy(() => import("./pages/Measure/Governance/Governance"));

const ActionCatalogueDetail = lazy(() =>
  import(
    "./pages/Plan/Action/NewActionPlan/NewActionMeasure/ActionCatalogue/ActionCatalogueDetail/ActionCatalogueDetail"
  )
);

function App() {
  const user = useSelector((state) => state.global.user);

  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();

  useEffect(() => {
    if (user) {
      getUser({ id: user._id });
    }
  }, [user]);

  return (
    <ThemeProvider theme={Muitheme}>
      <ConfigProvider
        componentSize="default"
        theme={{
          token: {
            fontFamily: "Poppins, sans-serif"
          },
          components: {
            Radio: {
              dotSize: 10
            }
          }
        }}
      >
        <SnackbarProvider>
          <div className="kb-wrapper">
            <Router>
              <Suspense
                fallback={
                  <div className="h-100vh d-flex justify-content-center align-items-center">
                    <WidgetLoader />
                  </div>
                }
              >
                <Routes>
                  <Route element={<Home />}>
                    <Route path="/" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot" element={<ForgotPassword />} />
                    <Route path="/reset" element={<ResetPassword />} />
                    <Route path="/success" element={<Success />} />
                  </Route>
                  <Route element={<Layout />}>
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/organization"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.adminPermissions?.viewOrganization ? (
                            <Organization />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/peer"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.adminPermissions?.viewOrganization ? (
                            <LandingPage />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/peer/create"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.adminPermissions?.viewOrganization ? (
                            <CreatePeer />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/peer/selectpeers"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.adminPermissions?.viewOrganization ? (
                            <SelectPeer />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/peer/datarequest"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.adminPermissions?.viewOrganization ? (
                            <DataRequest />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/measure"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <Measure />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/waste"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <Waste />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/water"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <Water />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/energy"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <Energy />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dataquality"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <DataQuality />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/dataquality/breakdown"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <BreakDown />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/social"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <Social />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/governance"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <Governance />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/airemission"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.viewData ? (
                            <AirEmissions />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/measure/upload"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.editData ? (
                            <SingleUpload />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="airemission/measure/upload"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.editData ? (
                            <SingleUpload />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="waste/measure/upload"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.editData ? (
                            <SingleUpload />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="water/measure/upload"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.editData ? (
                            <SingleUpload />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="energy/measure/upload"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.editData ? (
                            <SingleUpload />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="social/upload"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.editData ? (
                            <EsgUpload />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="governance/upload"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.measure?.editData ? (
                            <EsgUpload />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/reports"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.accessReports?.viewReport ? (
                            userResult?.data?.data?.reportRole !== "Admin" ? (
                              <MyReportList />
                            ) : (
                              <Allreports />
                            )
                          ) : (
                            // <CreateReport/>
                            // <Reports />
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/auditlog"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.accessReports?.viewReport ? (
                            <AuditLog />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/reports/form"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.accessReports?.viewReport ? (
                            <Form />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/analysis"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.analysis?.viewData ? (
                            <Analysis />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/offset"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.reduce?.viewOffset ? (
                            <Offset />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/klean"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.reduce?.viewKlean ? (
                            <Klean />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/klean/solution"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.reduce?.viewKlean ? (
                            <Solution />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/targets"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewTarget ? (
                            <Targets />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/action"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewActionPlan ? (
                            <Action />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/action/cataloguedetail"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewActionPlan ? (
                            <ActionCatalogueDetail />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/action/newactionplan"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewActionPlan ? (
                            <NewActionPlan />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/action/newactionplan/newactionmeasure"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewActionPlan ? (
                            <NewActionMeasure />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/action/newactionplan/newactionmeasure/actioncatalogue"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewActionPlan ? (
                            <ActionCatalogue />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/simulation"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewSimulation ? (
                            <Simulations />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/simulation/measure"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewSimulation ? (
                            <Measures />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/simulation/measure/detail"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.act?.viewSimulation ? (
                            <Subcategory />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cbam/product"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.cbam?.viewProducts ? (
                            <Products />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cbam/importers"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.cbam?.viewImporters ? (
                            <Importers />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cbam/invoice"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.cbam?.viewInvoice ? (
                            <Invoice />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cbam/breakdown"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.cbam?.viewBreakdown ? (
                            <BreakdownDetails />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/user/userlist"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.adminPermissions?.viewUser ? (
                            <Userlist />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/user/permissions"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.adminPermissions?.viewPermissions ? (
                            <Permissions />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/user/permissions/checklist"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.adminPermissions?.viewPermissions ? (
                            <Checklist />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/employee"
                      element={
                        <PrivateRoute>
                          <Employee />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/supplier"
                      element={
                        <PrivateRoute>
                          <Supplier />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/allreports"
                      element={
                        <PrivateRoute>
                          {userResult.data?.data?.userGroup?.permissions?.accessReports?.viewReport ? (
                            <Allreports />
                          ) : (
                            userResult.status === "fulfilled" && <Navigate to="/dashboard" />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/allreports/create"
                      element={
                        <PrivateRoute>
                          <CreateReport />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/addcontributors"
                      element={
                        <PrivateRoute>
                          <AddContributors />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/myreports"
                      element={
                        <PrivateRoute>
                          <MyReportList />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/admin"
                      element={
                        <PrivateRoute>
                          <BRSRDashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/assignquestions"
                      element={
                        <PrivateRoute>
                          <AssignQuestion />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/bulkassignquestions"
                      element={
                        <PrivateRoute>
                          <GlobalAssignQuestionsBulk />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/assignedquestions"
                      element={
                        <PrivateRoute>
                          <AssignedQuestion />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/assignquestions/bulk"
                      element={
                        <PrivateRoute>
                          <BulkAssignQuestion />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/p4"
                      element={
                        <PrivateRoute>
                          <PrincipleFour />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/multipleanswers"
                      element={
                        <PrivateRoute>
                          <MultipleAnswers />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/temppreview"
                      element={
                        <PrivateRoute>
                          <ReportPdfPreview />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                  <Route element={<BRSRLayout />}>
                    <Route path="/questions" element={<BRSRnew />} />
                    <Route path="/approvequestions" element={<ApproveQuestions />} />
                  </Route>
                </Routes>
              </Suspense>
            </Router>
          </div>
        </SnackbarProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
