import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import control from "../../images/control.svg";
import mainControl from "../../images/main_control.svg";
import smallLogo from "../../images/smallLogo.png";
import { useLazyGetUserDetailByIdQuery } from "../../state/api";

import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { useWindowDimensions } from "../Helper/GetDimensions";
import "./Sidebar.scss";

const expandedLogo = "/images/expandedLogo.png";

const Sidebar = () => {
  const userId = useSelector((state) => state.global.user);
  const divRef = useRef(null);
  const [user, setUser] = useState(null);
  const [openSubMenuMain, setOpenSubMenuMain] = useState(false);
  const [subMenu, openSubMenu] = useState({
    2: false,
    3: false,
    5: false,
    6: false,
    8: false
  });
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();

  const isIncluded = window.location.pathname.includes("addcontributors");
  useEffect(() => {
    getUser({ id: userId?._id });
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  const handleClick = (index) => {
    setOpenSubMenuMain(true);
    openSubMenu((prev) => {
      const newSubMenu = {};
      let toggleState = true;

      if (prev[index]) {
        toggleState = false;
      }

      for (const key in prev) {
        newSubMenu[key] = key === String(index) ? toggleState : false;
      }

      return newSubMenu;
    });
  };

  const { width } = useWindowDimensions();
  const location = useLocation();

  const [open, setOpen] = useState(true);


  useEffect(() => {
    if (isIncluded) {
      setOpen(false);
    }
  }, [isIncluded]);

  const sideBarVar = [
    {
      name: "Dashboard",
      image: "/images/dashboard/dashboard.png",
      link: "/dashboard",
      isActive: user?.userGroup?.permissions?.dashboard?.fullAccess ? true : false,
      isVisible: user?.userGroup?.permissions?.dashboard?.fullAccess ? true : false
    },
    {
      name: "Organization",
      image: "/images/dashboard/organization.png",
      link: "/organization",
      isActive: user?.userGroup?.permissions?.adminPermissions?.viewOrganization ? true : false,
      isVisible: user?.userGroup?.permissions?.adminPermissions?.viewOrganization ? true : false
    },
    {
      name: "Users",
      image: "/images/dashboard/user.svg",
      isActive:
        user?.userGroup?.permissions?.adminPermissions?.viewUser ||
        user?.userGroup?.permissions?.adminPermissions?.viewPermissions
          ? true
          : false,
      isVisible:
        user?.userGroup?.permissions?.adminPermissions?.viewUser ||
        user?.userGroup?.permissions?.adminPermissions?.viewPermissions
          ? true
          : false,
      subItem: [
        {
          name: "User List",
          image: "/images/dashboard/User_List.svg",
          link: "/user/userlist",
          isActive: user?.userGroup?.permissions?.adminPermissions?.viewUser ? true : false,
          isVisible: user?.userGroup?.permissions?.adminPermissions?.viewUser ? true : false
        },
        {
          name: "Permissions",
          image: "/images/dashboard/Permissions.svg",
          link: "/user/permissions",
          isActive: user?.userGroup?.permissions?.adminPermissions?.viewPermissions ? true : false,
          isVisible: user?.userGroup?.permissions?.adminPermissions?.viewPermissions ? true : false
        }
      ]
    },
    {
      name: "Measure",
      image: "/images/dashboard/measure.png",
      isActive:
        user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewData
          ? true
          : false,
      isVisible:
        user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewData
          ? true
          : false,
      subItem: [
        {
          name: "Social",
          image: "/images/dashboard/social.svg",
          link: "/social",
          isActive:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewSocialData
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewSocialData
              ? true
              : false
        },
        {
          name: "Governance",
          image: "/images/dashboard/governance.svg",
          link: "/governance",
          isActive:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewGovtData
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewGovtData
              ? true
              : false
        },
        {
          name: "Emissions",
          image: "/images/dashboard/emission.svg",
          link: "/measure",
          isActive:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewData
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewData
              ? true
              : false
        },
        {
          name: "Air Emissions",
          image: "/images/dashboard/airem.svg",
          link: "/airemission",
          isActive:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewAirData
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewAirData
              ? true
              : false
        },
        {
          name: "Waste",
          image: "/images/dashboard/waste.svg",
          link: "/waste",
          isActive:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewWasteData
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewWasteData
              ? true
              : false
        },
        {
          name: "Water",
          image: "/images/dashboard/water.svg",
          link: "/water",
          isActive:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewWaterData
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewWaterData
              ? true
              : false
        },
        {
          name: "Energy",
          image: "/images/dashboard/energy.svg",
          link: "/energy",
          isActive:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewEnergyData
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewEnergyData
              ? true
              : false
        },
        {
          name: "Data Quality",
          image: "/images/dashboard/dataquality.svg",
          link: "/dataquality",
          isActive:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewdataquality
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.measure?.fullAccess || user?.userGroup?.permissions?.measure?.viewdataquality
              ? true
              : false
        }
      ]
    },
    {
      name: "Analysis",
      image: "/images/dashboard/analysis.svg",
      link: "/analysis",
      isActive: user?.userGroup?.permissions?.analysis?.fullAccess ? true : false,
      isVisible: user?.userGroup?.permissions?.analysis?.fullAccess ? true : false
    },
    {
      name: "Act",
      image: "/images/dashboard/act.svg",
      isActive:
        !user?.userGroup?.permissions?.act?.viewTarget &&
        !user?.userGroup?.permissions?.act?.viewSimulation &&
        !user?.userGroup?.permissions?.act?.viewActionPlan
          ? false
          : true,
      isVisible:
        user?.userGroup?.permissions?.act?.viewTarget ||
        user?.userGroup?.permissions?.act?.viewSimulation ||
        user?.userGroup?.permissions?.act?.viewActionPlan
          ? true
          : false,
      subItem: [
        {
          name: "Targets",
          image: "/images/dashboard/Targets.svg",
          link: "/targets",
          isActive:
            user?.userGroup?.permissions?.act?.fullAccess || user?.userGroup?.permissions?.act?.viewTarget
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.act?.fullAccess || user?.userGroup?.permissions?.act?.viewTarget
              ? true
              : false
        },
        {
          name: "Simulation",
          image: "/images/dashboard/Scenarios.svg",
          link: "/simulation",
          isActive:
            user?.userGroup?.permissions?.act?.fullAccess || user?.userGroup?.permissions?.act?.viewSimulation
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.act?.fullAccess || user?.userGroup?.permissions?.act?.viewSimulation
              ? true
              : false
        },
        {
          name: "Action Plan",
          image: "/images/dashboard/Action_Plan.svg",
          link: "/action",
          isActive:
            user?.userGroup?.permissions?.act?.fullAccess || user?.userGroup?.permissions?.act?.viewActionPlan
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.act?.fullAccess || user?.userGroup?.permissions?.act?.viewActionPlan
              ? true
              : false
        }
      ]
    },
    {
      name: "Reduce",
      image: "/images/dashboard/reduce.svg",
      isActive:
        !user?.userGroup?.permissions?.reduce?.viewKlean && !user?.userGroup?.permissions?.reduce?.viewOffset
          ? false
          : true,
      isVisible:
        user?.userGroup?.permissions?.reduce?.viewKlean || user?.userGroup?.permissions?.reduce?.viewOffset
          ? true
          : false,
      subItem: [
        {
          name: "Klean",
          image: "/images/dashboard/Klean.svg",
          link: "/klean",
          isActive:
            user?.userGroup?.permissions?.reduce?.fullAccess || user?.userGroup?.permissions?.reduce?.viewKlean
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.reduce?.fullAccess || user?.userGroup?.permissions?.reduce?.viewKlean
              ? true
              : false
        },
        {
          name: "Offset",
          image: "/images/dashboard/Offset.svg",
          link: "/offset",
          isActive:
            user?.userGroup?.permissions?.reduce?.fullAccess || user?.userGroup?.permissions?.reduce?.viewOffset
              ? true
              : false,
          isVisible:
            user?.userGroup?.permissions?.reduce?.fullAccess || user?.userGroup?.permissions?.reduce?.viewOffset
              ? true
              : false
        }
      ]
    },
    {
      name: "Reports",
      image: "/images/dashboard/reports.png",
      link: "/reports",
      isActive:
        user?.userGroup?.permissions?.accessReports?.fullAccess ||
        user?.userGroup?.permissions?.accessReports?.viewReport
          ? true
          : false,
      isVisible:
        user?.userGroup?.permissions?.accessReports?.fullAccess ||
        user?.userGroup?.permissions?.accessReports?.viewReport
          ? true
          : false
    }
    // {
    //   name: "CBAM",
    //   image: "/images/dashboard/cbam.svg",
    //   isActive: !user?.userGroup?.permissions?.cbam?.viewProducts && !user?.userGroup?.permissions?.cbam?.viewImporters && !user?.userGroup?.permissions?.cbam?.viewInvoices && !user?.userGroup?.permissions?.cbam?.viewDetails ? false : true,
    //   isVisible: user?.userGroup?.permissions?.cbam?.viewProducts || user?.userGroup?.permissions?.cbam?.viewImporters || user?.userGroup?.permissions?.cbam?.viewInvoices || user?.userGroup?.permissions?.cbam?.viewDetails ? true : false,
    //   subItem: [
    //     {
    //       name: "Products",
    //       link: "/cbam/product",
    //       isActive: user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewProducts ? true : false,
    //       isVisible: user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewProducts ? true : false
    //     },
    //     {
    //       name: "Importers",
    //       link: "/cbam/importers",
    //       isActive: user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewImporters ? true : false,
    //       isVisible: user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewImporters ? true : false
    //     },
    //     {
    //       name: "Invoices",
    //       link: "/cbam/invoice",
    //       isActive: user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewInvoice ? true : false,
    //       isVisible: user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewInvoice ? true : false
    //     },
    //     {
    //       name: "Breakdown Details",
    //       link: "/cbam/breakdown",
    //       isActive: user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewBreakdown ? true : false,
    //       isVisible: user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewBreakdown ? true : false
    //     }
    //   ]
    // },
    // {
    //   name: "Employees",
    //   image: "/images/dashboard/user-add.svg",
    //   link: "/employee",
    //   isActive: true,
    //   isVisible: true
    // },
    // {
    //   name: "Suppliers",
    //   image: "/images/dashboard/truck.svg",
    //   link: "/supplier",
    //   isActive: true,
    //   isVisible: true
    // }
    // {
    //   name: "CBAM",
    //   image: "/images/dashboard/cbam.svg",
    //   isActive:
    //     !user?.userGroup?.permissions?.cbam?.viewProducts &&
    //     !user?.userGroup?.permissions?.cbam?.viewImporters &&
    //     !user?.userGroup?.permissions?.cbam?.viewInvoices &&
    //     !user?.userGroup?.permissions?.cbam?.viewDetails
    //       ? false
    //       : true,
    //   isVisible:
    //     user?.userGroup?.permissions?.cbam?.viewProducts ||
    //     user?.userGroup?.permissions?.cbam?.viewImporters ||
    //     user?.userGroup?.permissions?.cbam?.viewInvoices ||
    //     user?.userGroup?.permissions?.cbam?.viewDetails
    //       ? true
    //       : false,
    //   subItem: [
    //     {
    //       name: "Products",
    //       link: "/cbam/product",
    //       isActive:
    //         user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewProducts
    //           ? true
    //           : false,
    //       isVisible:
    //         user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewProducts
    //           ? true
    //           : false
    //     },
    //     {
    //       name: "Importers",
    //       link: "/cbam/importers",
    //       isActive:
    //         user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewImporters
    //           ? true
    //           : false,
    //       isVisible:
    //         user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewImporters
    //           ? true
    //           : false
    //     },
    //     {
    //       name: "Invoices",
    //       link: "/cbam/invoice",
    //       isActive:
    //         user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewInvoice
    //           ? true
    //           : false,
    //       isVisible:
    //         user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewInvoice
    //           ? true
    //           : false
    //     },
    //     {
    //       name: "Breakdown Details",
    //       link: "/cbam/breakdown",
    //       isActive:
    //         user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewBreakdown
    //           ? true
    //           : false,
    //       isVisible:
    //         user?.userGroup?.permissions?.cbam?.fullAccess || user?.userGroup?.permissions?.cbam?.viewBreakdown
    //           ? true
    //           : false
    //     }
    //   ]
    // }
  ];

  const settingSidebar = {
    name: "Settings",
    image: "/images/icons/setting-2.svg",
    isActive:
      user?.userGroup?.permissions?.accessReports?.fullAccess || user?.userGroup?.permissions?.accessReports?.viewReport
        ? true
        : false,
    isVisible:
      user?.userGroup?.permissions?.accessReports?.fullAccess || user?.userGroup?.permissions?.accessReports?.viewReport
        ? true
        : false,

    subItem: [
      {
        name: "Audit Log",
        image: "/images/icons/setting-2.svg",
        link: "/auditlog",
        isActive:
          user?.userGroup?.permissions?.accessReports?.fullAccess ||
          user?.userGroup?.permissions?.accessReports?.viewReport
            ? true
            : false,
        isVisible:
          user?.userGroup?.permissions?.accessReports?.fullAccess ||
          user?.userGroup?.permissions?.accessReports?.viewReport
            ? true
            : false
      }
    ]
  };

  const handleToggleSidebar = () => {
    // if(open){
    //   setOpenSubMenuMain(false)
    //   handleClick(0)
    // }


    setOpen(!open);

  };

  const handleToggleSubSidebar = () => {
    handleClick(0);
  };

  return (
    <>
      <div className={open ? `sidebar` : "sidebar collapsedState"} ref={divRef}>
        {Object.values(subMenu).every((value) => value === false) ? (
          !open && (
            <img
              alt="control"
              src={mainControl}
              // className={Object.values(subMenu).every((value) => value === false) ? "toggle-button rotate" : "toggle-button"}
              className={!open ? "toggle-button rotate" : "toggle-button"}
              onClick={() => handleToggleSidebar()}
              // onClick={() => handleToggleSubSidebar()}
            />
          )
        ) : (
          <img
            alt="control"
            src={control}
            className={
              Object.values(subMenu).every((value) => value === false) ? "toggle-button rotate" : "toggle-button"
            }
            onClick={() => handleToggleSubSidebar()}
          />
        )}
        <div>
          <div className="d-flex justify-content-between align-items-center w-100 mb-24p">
            <div className={open ? "titleText" : "titleText hiddenTransition"}>
              <img
                src={open ? expandedLogo : smallLogo}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%"
                }}
                alt="Karbonn"
              />
            </div>
            {open && (
              <img
                alt="control"
                src={mainControl}
                style={{
                  opacity: open && Object.values(subMenu).every((value) => value === false) ? 1 : 0,
                  cursor: Object.values(subMenu).every((value) => value === false) ? "pointer" : "default"
                }}
                className={!open ? "toggle-button-main rotate" : "toggle-button-main "}
                onClick={() => {
                  if (open && Object.values(subMenu).every((value) => value === false)) {
                    handleToggleSidebar();
                  }
                }}
              />
            )}
          </div>
          <div className="scrollable-items-sidebar">
            <div>
              <ul>
                {sideBarVar.map(
                  (item, index) =>
                    item.isVisible &&
                    (item.subItem ? (
                      open ? (
                        <>
                          <div onClick={(e) => handleClick(index)}>
                            <li
                              className={`menu ${
                                item.subItem.some((sub) => location.pathname.startsWith(sub.link)) || subMenu[index]
                                  ? "active"
                                  : ""
                              } ${item.isActive ? "" : "disabled"}`}
                              key={index}
                            >
                              {item.isActive ? (
                                <div className={open ? `menuItemSub` : `menuItemSub2`}>
                                  <img className="vecImage" alt="Vector" src={item.image} />
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                                    <img
                                      className={"right-arrow"}
                                      style={!subMenu[index] ? { opacity: 50 } : { opacity: 100 }}
                                      src={"/images/icons/dropdown-icon-gray.svg"}
                                      alt="arrow"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="menuItem">
                                  <img className="vecImage opacity-50" alt="Vector" src={item.image} />
                                  <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                                </div>
                              )}
                            </li>
                          </div>
                        </>
                      ) : (
                        <div style={{ width: "42px" }} key={index}>
                          <CustomTooltip
                            key={index + "tooltip"}
                            content={`<p>${item.name}</p>`}
                            showIcon={true}
                            id={index + "facStatus-info"}
                            showContained={true}
                            position={"right"}
                            childComponent={
                              <li
                                className={`menu ${
                                  item.subItem.some((sub) => location.pathname.startsWith(sub.link)) || subMenu[index]
                                    ? "active"
                                    : ""
                                } ${item.isActive ? "" : "disabled"}`}
                                key={index}
                                onClick={() => handleClick(index)}
                              >
                                {item.isActive ? (
                                  <Link to={item.link} className={open ? `menuItemSub` : `menuItemSub2`}>
                                    {(item.name === "Suppliers" || item.name === "Employees") && open ? (
                                      <img className="vecImage2" alt="Vector" src={item.image} />
                                    ) : (
                                      <img className="vecImage" alt="Vector" src={item.image} />
                                    )}
                                    <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                                  </Link>
                                ) : (
                                  <a href="javascript:void(0)" className="menuItem">
                                    <img className="vecImage opacity-50" alt="Vector" src={item.image} />
                                    <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                                  </a>
                                )}
                              </li>
                            }
                          />
                        </div>
                      )
                    ) : open ? (
                      <li
                        className={`menu ${
                          location.pathname.startsWith(item.link) &&
                          Object.values(subMenu).every((value) => value === false)
                            ? "active"
                            : ""
                        } ${item.isActive ? "" : "disabled"}`}
                        key={index}
                        onClick={() => handleClick(index)}
                      >
                        {item.isActive ? (
                          <Link to={item.link} className={open ? `menuItemSub` : `menuItemSub2`}>
                            {(item.name === "Suppliers" || item.name === "Employees") && open ? (
                              <img className="vecImage2" alt="Vector" src={item.image} />
                            ) : (
                              <img className="vecImage" alt="Vector" src={item.image} />
                            )}
                            <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                          </Link>
                        ) : (
                          <a href="javascript:void(0)" className="menuItem">
                            <img className="vecImage opacity-50" alt="Vector" src={item.image} />
                            <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                          </a>
                        )}
                      </li>
                    ) : (
                      <div style={{ width: "42px" }}>
                        <CustomTooltip
                          key={index + "tooltip"}
                          content={`<p>${item.name}</p>`}
                          showIcon={true}
                          id={index + "facStatus-info"}
                          showContained={true}
                          position={"right"}
                          childComponent={
                            <li
                              className={`menu ${
                                location.pathname.startsWith(item.link) &&
                                Object.values(subMenu).every((value) => value === false)
                                  ? "active"
                                  : ""
                              } ${item.isActive ? "" : "disabled"}`}
                              key={index}
                              onClick={() => handleClick(index)}
                            >
                              {item.isActive ? (
                                <Link to={item.link} className={open ? `menuItemSub` : `menuItemSub2`}>
                                  {(item.name === "Suppliers" || item.name === "Employees") && open ? (
                                    <img className="vecImage2" alt="Vector" src={item.image} />
                                  ) : (
                                    <img className="vecImage" alt="Vector" src={item.image} />
                                  )}
                                  <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                                </Link>
                              ) : (
                                <a href="javascript:void(0)" className="menuItem">
                                  <img className="vecImage opacity-50" alt="Vector" src={item.image} />
                                  <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                                </a>
                              )}
                            </li>
                          }
                        />
                      </div>
                    ))
                )}
              </ul>
            </div>
            <div style={{ marginTop: "24px", transition: "400ms" }}>
              <p
                style={
                  open
                    ? { fontWeight: 500, fontSize: "14px", color: "white", padding: "10px", display: "block" }
                    : { display: "none" }
                }
              >
                Settings
              </p>
              <ul className={open ? "mt-8i" : "mt-0"}>
                {settingSidebar.subItem.map((item) => (
                  <li
                    className={`menu ${location.pathname.startsWith(item.link) ? "active" : ""} ${
                      item.isActive ? "" : "disabled"
                    }`}
                  >
                    <Link to={item.link} className={open ? `menuItemSub` : `menuItemSub2`}>
                      {(item.name === "Suppliers" || item.name === "Employees") && open ? (
                        <img className="vecImage2" alt="Vector" src={item.image} />
                      ) : (
                        <img className="vecImage" alt="Vector" src={item.image} />
                      )}
                      <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {openSubMenuMain &&
          sideBarVar.map(
            (item, index) =>
              item.isVisible &&
              item.subItem && (
                <div className={`sub-sidebar ${subMenu[index] ? "open" : ""}`} onClick={(e) => handleClick(0)}>
                  {subMenu[index] && (
                    <>
                      <p className="p-heading-style">{item.name}</p>
                      {item.subItem.map(
                        (child, idx) =>
                          child.isVisible && (
                            <li
                              className={`menu ${location.pathname.startsWith(child.link) ? "active" : ""} ${
                                child.isActive ? "" : "disabled"
                              }`}
                              key={idx}
                              style={{ listStyle: "none" }}
                            >
                              {item.isActive ? (
                                <Link to={child.link} className="menuItemSub">
                                  {child.image && <img className="vecImage" alt="Vector" src={child.image} />}
                                  <p className="textWrapper">
                                    {child.name}
                                    {/* <span className="beta">BETA</span> */}
                                  </p>
                                </Link>
                              ) : (
                                <a href="javascript:void(0)" className="menuItem">
                                  <img className="vecImage opacity-50" alt="Vector" src={item.image} />
                                  <p className={open ? "textWrapper" : "textWrapper hidden"}>{item.name}</p>
                                </a>
                              )}
                            </li>
                          )
                      )}
                    </>
                  )}
                </div>
              )
          )}
      </div>
    </>
  );
};

export default Sidebar;
