import React, { useState } from "react";
import "./GlobalSelectMember.scss";
import "../../common.scss";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Checkbox } from "antd";

export const GlobalSelectMember = ({
  checkDisabled,
  handleChecked,
  facId,
  userId,
  role,
  members,
  name,
  email,
  facility,
  designation,
  department,
  index,
  contributors
}) => {
  return (
    <div
      className={`global-team-wrapper ${
        index === 0 ? "first-item" : index === contributors.length - 1 ? "last-item" : ""
      }`}
    >
      <div className="d-flex justify-content-between gap-2 flex-fill">
        <div className="user-info d-flex justify-content-center gap-2 flex-fill">
          <div className="check-role">
            <Checkbox
              disabled={
                checkDisabled.length && checkDisabled?.find((user) => user._id === userId && user.facilityId === facId)
              }
              checked={members[`${userId}.${facId}.${role}`]}
              onChange={(e) => handleChecked(e, facId, userId, role, true)}
            />
          </div>
          <div>
            <Avatar size={38} icon={<UserOutlined />} />
          </div>
          <div className="user-details-select flex-fill">
            <p className="user-name">{name}</p>
            <p className="user-email">{email}</p>
            <p className="user-email">{facility}</p>
            <>
              <div className="user-email">DSG: {designation}</div>
              <div className="user-email">DPT: {department?.length > 0 ? department?.join(", ") : ""}</div>
            </>
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-end"
          style={role !== "maker" ? { width: "25%", wordBreak: "break-word" } : {}}
        >
          <img src="/images/BRSR/maker-icon.svg" alt="" />
          <p className="select-text">
            {role === "reviewer/approver" ? "Approver" : role === "maker" ? "Maker" : "Admin"}
          </p>
        </div>
      </div>
    </div>
  );
};
