import React, { useState } from "react";
import "./SelectMember.scss";
import "../../common.scss";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Checkbox } from "antd";

export const SelectMember = ({
  qId,
  qNo,
  bulk,
  facility,
  name,
  email,
  department,
  designation,
  role,
  facId,
  userId,
  handleChecked,
  members,
  checkDisabled,
  facilityAssignment
}) => {

  return (
    <>
      <div className={`team-wrapper`} style={bulk ? { minWidth: "370px" } : {}}>
        <div className="d-flex justify-content-between gap-2 flex-fill">
          <div className="user-info d-flex justify-content-center gap-2 flex-fill">
            <div className="check-role">
              <Checkbox
                disabled={
                  bulk
                    ? checkDisabled[qId]?.find((user) => user._id === userId && user.facilityId === facId)
                    : checkDisabled.length &&
                      checkDisabled?.find((user) => user._id === userId && user.facilityId === facId)
                }
                checked={bulk ? members[`${qId}.${userId}.${facId}.${role}`] : members[`${userId}.${facId}.${role}`]}
                onChange={
                  bulk
                    ? (e) => handleChecked(e, qId, qNo, facId, userId, role, facilityAssignment, true)
                    : (e) => handleChecked(e, facId, userId, role, true)
                }
              />
            </div>
            <div>
              <Avatar size={38} icon={<UserOutlined />} />
            </div>
            <div className="user-details-select flex-fill">
              <p className="user-name">{name}</p>
              <p className="user-email">{email}</p>
              <p className="user-email">{facility}</p>
              <>
                <div className="user-email">DSG: {designation}</div>
                <div className="user-email">DPT: {department?.length > 0 ? department?.join(", ") : ""}</div>
              </>
            </div>
          </div>
          <div
            className="d-flex align-items-center"
            style={role !== "maker" ? { width: "25%", wordBreak: "break-word" } : {}}
          >
            <img src="/images/BRSR/maker-icon.svg" alt="" />
            <p className="select-text">{role === "reviewer/approver" ? "Approver" :  role === "maker" ? "Maker" : "Admin"}</p>
          </div>
        </div>
      </div>
    </>
  );
};
