import React, { useState, useEffect, useRef, memo } from "react";
import "./CustomSectionDropdown.scss";

const CustomSectionDropdown = ({ sections, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSectionClick = (section) => {
    setSelectedSubsection(null);
    setSelectedSection(section);
    setSelectedValue(`${section.name}`);
    onChange(`${section.name}`);
  };

  const handleSubsectionClick = (section, subsection) => {
    setSelectedSection(section);
    setSelectedSubsection(subsection);
    setSelectedValue(`${section.name} / ${subsection}`);
    onChange(`${section.name} / ${subsection}`);
  };

  return (
    <div className="custom-dropdown-container" ref={dropdownRef}>
      <div className="dropdown-trigger" onClick={() => setIsOpen(!isOpen)}>
        {selectedValue || "Select section/sub section"}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M16 11L12 15L8 11"
            stroke="#16161E"
            stroke-opacity="0.7"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      {isOpen && (
        <div className="dropdown-menu">
          <div className="sections-list">
            {sections.map((section) => (
              <div
                key={section.name}
                className={`section-item ${
                  selectedSection?.name === section.name
                    ? `active ${section.name.replace(/\s+/g, "-").toLowerCase()}`
                    : ""
                }`}
                onClick={() => handleSectionClick(section)}
              >
                <div className="section-item-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="#16161E"
                      stroke-opacity="0.35"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="section-item-text">
                  <div className="section-name">{section.name}</div>
                  <div className="section-tag">{section.tag}</div>
                </div>
              </div>
            ))}
          </div>
          {selectedSection ? (
            <div className="subsections-list">
              {selectedSection.subsections.map((subsection) => (
                <div
                  key={subsection}
                  className={`subsection-item ${
                    selectedSubsection === subsection
                      ? `active ${selectedSection.name.replace(/\s+/g, "-").toLowerCase()}`
                      : ""
                  }`}
                  onClick={() => handleSubsectionClick(selectedSection, subsection)}
                >
                  <span className="subsection-name" style={{ color: "#16161E" }}>
                    {subsection}
                  </span>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CustomSectionDropdown;
