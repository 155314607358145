import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analysisName: "",
  region: "",
  industry: "",
  year: "",
};

const peerAnalysisSlice = createSlice({
  name: "createPeer",
  initialState,
  reducers: {
    setAnalysisName(state, action) {
      state.analysisName = action.payload;
    },
    setRegion(state, action) {
      state.region = action.payload;
    },
    setIndustry(state, action) {
      state.industry = action.payload;
    },
    setYear(state, action) {
      state.year = action.payload;
    },
    resetState() {
      return initialState;
    },
  },
});

export const { setAnalysisName, setRegion, setIndustry, setYear, resetState } = peerAnalysisSlice.actions;
export default peerAnalysisSlice.reducer;