import React, { useState } from "react";
import "./CustomFacilityDropdown.scss";

export const CustomFacilityDropdown = ({ options, value, onChange, placeholder, contributors }) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = options?.find((opt) => opt._id === value);

  return (
    <div className="dropdown-container">
      <button className={isOpen ? "dropdown-button-open" : "dropdown-button-close"} onClick={() => setIsOpen(!isOpen)}>
        <div className="dropdown-placeholder">
          <div className="dropdown-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M2.75 8.41602C2.75 7.31145 3.64543 6.41602 4.75 6.41602H17.25C18.3546 6.41602 19.25 7.31145 19.25 8.41602V16.3327C19.25 17.4373 18.3546 18.3327 17.25 18.3327H4.75C3.64543 18.3327 2.75 17.4373 2.75 16.3327V8.41602Z"
                stroke="#16161E"
                stroke-width="1.6"
                stroke-linejoin="round"
              />
              <path
                d="M7.33301 6.41602V4.66602C7.33301 4.11373 7.78072 3.66602 8.33301 3.66602H13.6663C14.2186 3.66602 14.6663 4.11373 14.6663 4.66602V6.41602"
                stroke="#16161E"
                stroke-width="1.6"
                stroke-linejoin="round"
              />
              <ellipse
                cx="11.0003"
                cy="11.9173"
                rx="1.83333"
                ry="1.83333"
                stroke="#16161E"
                stroke-width="1.6"
                stroke-linejoin="round"
              />
              <path d="M2.75 11.916H9.16667" stroke="#16161E" stroke-width="1.6" stroke-linejoin="round" />
              <path d="M12.833 11.916H19.2497" stroke="#16161E" stroke-width="1.6" stroke-linejoin="round" />
            </svg>
          </div>

          <div className="dropdown-placeholder-option">
            <span>{selectedOption ? selectedOption.name : placeholder}</span>
            {contributors && <span className="contributors-count">Contributors: {contributors.length}</span>}
          </div>
        </div>
        {isOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M16 14L12 10L8 14"
              stroke="#16161E"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M16 11L12 15L8 11"
              stroke="#16161E"
              stroke-width="1.8"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </button>

      <div className={`dropdown-options-wrapper ${isOpen ? "open" : "closed"}`} style={{ overflow: "hidden" }}>
        {isOpen && (
          <div className="dropdown-options">
            <button
              key={"ALL"}
              className={`dropdown-option ${value === "ALL" ? "selected" : ""}`}
              onClick={() => {
                onChange("ALL");
                //   setIsOpen(false);
              }}
            >
              <span>All Facilities</span>
              {/* {contributors && <span className="contributors-count">Contributors: {contributors.length}</span>} */}
            </button>
            {options.map((option, index) => (
              <button
                key={option._id}
                className={`dropdown-option ${value === option._id ? "selected" : ""}`}
                onClick={() => {
                  onChange(option._id);
                  // setIsOpen(false);
                }}
              >
                <span className={`dropdown-option-index ${value === option._id ? "selected" : ""}`}>{index + 1}</span>
                <span>{option.name}</span>
                {/* {contributors && <span className="contributors-count">Contributors: {contributors.length}</span>} */}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
