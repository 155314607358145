import React from "react";
import "./BRSRButton.scss";

const BRSRButton = ({ children, onClick, fontSize, questionSelected = false }) => {
  return (
    <button
      className={questionSelected ? "disable-custom-button" : `custom-button`}
      onClick={onClick}
      style={{ fontSize }}
      disabled={questionSelected}
    >
      {children}
    </button>
  );
};

export default BRSRButton;
