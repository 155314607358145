import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./GlobalBulkAssignDrawer.scss";
import "../../common.scss";
import "../../../../App.scss";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import {
  useLazyGetContributorsQuery,
  useLazyGetUserDetailByIdQuery,
  usePostGlobalBulkAssignQuestionMutation
} from "../../../../state/api";
import { useSnackbar } from "../../../../components/snackbar/SnackbarContext";
import { useSelector } from "react-redux";
import { updateOrCreate, updateOrReplace } from "../../../../helper/genericFuntions";
import { GlobalSelectMember } from "../GlobalSelectMember/GlobalSelectMember";
import { removeOnlyFacilityIdObjects } from "../../../../helper/Utils/utilitiyFunctions";
import { CustomFacilityDropdown } from "../../../../components/CustomFacilityDropdown/CustomFacilityDropdown";
import EmptyStateCustom from "../../../../components/BRSRComponets/EmptyState/CustomEmptyState";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../../../components/Loaders/ButtonLoader";

export const GlobalBulkAssignDrawer = ({
  isOpen,
  onClose,
  questionData,
  reportId,
  department,
  facilityAssignment,
  error,
  setError,
  setQuestionSelected,
  setQuestionSelectedDetail
}) => {
  const [getContributors, resultContributors] = useLazyGetContributorsQuery();
  const [globalBulkAssign] = usePostGlobalBulkAssignQuestionMutation();

  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const navigate = useNavigate();

  const { openSnackbar } = useSnackbar();

  const [facFilter, setFacFilter] = useState("ALL");
  const [members, setMembers] = useState({});
  const [memberArr, setMemberArr] = useState([]);
  const [checkDisabled, setCheckDisabled] = useState([]);
  const [manualChecked, setManualChecked] = useState(false);
  const [isBulkAssigning, setIsBulkAssigning] = useState(false);

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    if (department) {
      getContributors({ facility: facFilter, department }, true);
    }
  }, [facFilter, department]);

  const moveToInviteUser = () => {
    navigate("/user/userlist");
  };

  const handleChecked = (e, fac, user, role, cannotSkip) => {
    setManualChecked(true);
    let obj = { ...members };
    let arr = [];

    if (facilityAssignment === "single" || facilityAssignment === "BOTH") {
      Object.keys(obj).forEach((key) => {
        const [existingUser, existingFac, existingRole] = key.split(".");
        if (existingRole === role) {
          obj[key] = false;
        }
      });
      obj[`${user}.${fac}.${role}`] = e.target.checked;
      setMembers(obj);
    } else {
      obj[`${user}.${fac}.${role}`] = e.target.checked;
      setMembers({ ...members, ...obj });
    }

    if (role === "maker") {
      if (e.target.checked) {
        if (facilityAssignment === "single") {
          console.log("memberArr:", memberArr);
          arr = updateOrReplace(memberArr, fac, { makerId: user });
          setMemberArr(arr);
        } else {
          arr = updateOrCreate(memberArr, fac, { makerId: user });
          setMemberArr(arr);
        }

        let contr;

        let updatedCheckDisabled = checkDisabled.filter((cont) => !(cont.role === role));
        contr = resultContributors.data?.data?.filter(
          (cont) => cont.role === role && cont.facilityId === fac && cont._id !== user
        );

        setCheckDisabled([...updatedCheckDisabled, ...contr]);
      } else {
        let newMembers = memberArr.map((obj) => {
          if (obj.facilityId === fac && obj.makerId === user) {
            const newObj = { ...obj };
            delete newObj["makerId"];
            return newObj;
          }
          return obj;
        });
        newMembers = removeOnlyFacilityIdObjects(newMembers);
        setMemberArr(newMembers);
        let contr;

        contr = checkDisabled.filter((cont) => !(cont.role === role && cont.facilityId === fac && cont._id !== user));
        setCheckDisabled(contr);
      }
    } else if (role === "reviewer/approver") {
      if (e.target.checked) {
        if (facilityAssignment === "single" || facilityAssignment === "BOTH") {
          arr = updateOrReplace(memberArr, fac, { reviewerApproverId: user });
          setMemberArr(arr);
        } else {
          arr = updateOrCreate(memberArr, fac, { reviewerApproverId: user });
          setMemberArr(arr);
        }

        let contr;

        let updatedCheckDisabled = checkDisabled.filter((cont) => !(cont.role === role));
        contr = resultContributors.data?.data?.filter(
          (cont) => cont.role === role && cont.facilityId === fac && cont._id !== user
        );

        setCheckDisabled([...updatedCheckDisabled, ...contr]);
      } else {
        let newMembers = memberArr.map((obj) => {
          if (obj.facilityId === fac && obj.reviewerApproverId === user) {
            const newObj = { ...obj };
            delete newObj["reviewerApproverId"];
            return newObj;
          }
          return obj;
        });
        newMembers = removeOnlyFacilityIdObjects(newMembers);
        setMemberArr(newMembers);
        let contr;

        contr = checkDisabled.filter((cont) => !(cont.role === role && cont.facilityId === fac && cont._id !== user));

        setCheckDisabled(contr);
      }
    }
  };

  const handleSubmit = async () => {
    setIsBulkAssigning(true);
    const questionIds = questionData.map((question) => question.id);

    let postObj = {
      questionIds: questionIds,
      reportId,
      contributors: memberArr
    };

    try {
      const response = await globalBulkAssign(postObj);
      if (response.error) {
        setError(response.error.data?.message);
      } else {
        openSnackbar(response.data?.message, "success");
        setQuestionSelected({});
        setQuestionSelectedDetail([]);
        setMembers({});
        setMemberArr([]);
        setIsBulkAssigning(false);
        onClose();
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred";
      setIsBulkAssigning(false);
      openSnackbar(errorMessage, "error");
    }
  };

  return (
    <>
      <div className="drawer-container">
        <Drawer open={isOpen} onClose={onClose} anchor="right">
          <div className="drawer-main">
            <div className="bulk-history-drawer">
              <div onClick={onClose}>
                <button className="drawer-cross-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 18 18" fill="none">
                    <path
                      d="M6 12L12 6M12 12L6 6"
                      stroke="#1F93EF"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="close-text">Close</p>
                </button>
              </div>
              <p className="drawer-heading">Assign Questions</p>

              <div style={{ marginBottom: "2px" }}>
                <CustomFacilityDropdown
                  options={user?.facilities}
                  onChange={(newValue) => setFacFilter(newValue)}
                  value={facFilter}
                  placeholder="All Facilities"
                  contributors={resultContributors.data?.data}
                />
                {/* <Select
                  value={facFilter}
                  placeholder="Select Facility"
                  optionFilterProp="facility"
                  size="medium"
                  onChange={(newValue) => setFacFilter(newValue)}
                  className="br-8"
                >
                  {user?.userGroup?.name === "Admin" && <Select.Option value={"ALL"}>All Facilities</Select.Option>}
                  {user?.facilities?.map((item, ind) => (
                    <Select.Option key={ind} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select> */}
              </div>
              <div>
                {resultContributors.data?.data.length !== 0 ? (
                  resultContributors.data?.data?.map((item, index) => (
                    <>
                      {item.role && (
                        <GlobalSelectMember
                          checkDisabled={checkDisabled}
                          handleChecked={handleChecked}
                          facility={item.facilityName}
                          department={item.department}
                          members={members}
                          name={item.name}
                          email={item.email}
                          designation={item.designation}
                          role={item.role}
                          facId={item.facilityId}
                          userId={item._id}
                          index={index}
                          contributors={resultContributors.data?.data}
                        />
                      )}
                    </>
                  ))
                ) : (
                  <EmptyStateCustom
                    svg={
                      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                        <circle cx="21.0003" cy="16.3333" r="9.33333" stroke="#E2E2EA" stroke-width="2.4" />
                        <path
                          d="M4.66699 36.166C4.66699 34.233 6.234 32.666 8.16699 32.666H33.8337C35.7667 32.666 37.3337 34.233 37.3337 36.166V48.9993H4.66699V36.166Z"
                          stroke="#E2E2EA"
                          stroke-width="2.4"
                          stroke-linejoin="round"
                        />
                        <path d="M44.334 17.5V31.5" stroke="#E2E2EA" stroke-width="2.4" stroke-linejoin="round" />
                        <path d="M37.334 24.5H51.334" stroke="#E2E2EA" stroke-width="2.4" stroke-linejoin="round" />
                      </svg>
                    }
                    activeMessage={"No contributor found!"}
                    activeSubMessage={
                      facFilter == "ALL"
                        ? "No user is mapped for the selected department"
                        : "No user is assigned as a contributor to the facility chosen"
                    }
                    width="440px"
                  >
                    <div>
                      <button
                        type="button"
                        className="btn btn-success rounded-2"
                        style={{ backgroundColor: "ActiveBorder" }}
                        onClick={moveToInviteUser}
                      >
                        {"Proceed to add contributors >"}
                      </button>
                    </div>
                  </EmptyStateCustom>
                )}
              </div>
            </div>
            <div className="drawer-button-container">
              <PrimaryButton disabled={!manualChecked} onClick={handleSubmit} fullWidth={true}>
                {isBulkAssigning ? <ButtonLoader /> : "Assign"}
              </PrimaryButton>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
