import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../../../components/Accordion/Accordion";
import "../../../../Reports/BRSR/Brsr.scss";
import "../../Social.scss";
import { setPageWiseData } from "../../../../../state/SocialSlice";
import { useLazyGetUserDetailByIdQuery } from "../../../../../state/api";
import { Select } from "antd";
import { numberRegex } from "../../../../../helper/Rejex";

const Employment = ({ formEditable, editMode }) => {
  const { pageWiseData } = useSelector((state) => state.social);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const [accordianActiveIndex, setaccordianActiveIndex] = useState(null);

  let socialAllData = JSON.parse(JSON.stringify(pageWiseData));

  useEffect(() => {
    getUser({ id: userId._id }, true);
  }, []);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  const handleNewHireEmployee = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "newHireEmployeRatio") {
        if (value <= 100) {
          socialAllData.Employment.newEmployeeTurnover.newHireEmployee[index][name] = value;
        }
      } else {
        socialAllData.Employment.newEmployeeTurnover.newHireEmployee[index][name] = value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeesTurnover = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "newHireEmployeTurnOverRatio") {
        if (value <= 100) {
          socialAllData.Employment.newEmployeeTurnover.employeesTurnover[index][name] = value;
        }
      } else {
        socialAllData.Employment.newEmployeeTurnover.employeesTurnover[index][name] = value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleVoluntaryTurnover = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "newHireEmployeTurnOverRatio") {
        if (value <= 100) {
          socialAllData.Employment.newEmployeeTurnover.voluntaryTurnover[index][name] = value;
        }
      } else {
        socialAllData.Employment.newEmployeeTurnover.voluntaryTurnover[index][name] = value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeGenderWise = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "newHireEmployeePercentage" || name === "newHireEmployeRatio") {
        if (value <= 100) {
          socialAllData.Employment.newEmployeeTurnover.employeeGenderWise[index][name] = value;
        }
      } else {
        socialAllData.Employment.newEmployeeTurnover.employeeGenderWise[index][name] = value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeAgeWise = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "newHireEmployeRatio" || name === "newHireEmployeePercentage") {
        if (value <= 100) {
          socialAllData.Employment.newEmployeeTurnover.employeeAgeWise[index][name] = value;
        }
      } else {
        socialAllData.Employment.newEmployeeTurnover.employeeAgeWise[index][name] = value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeRegionWise = (e, index) => {
    const { name, value } = e.target;
    if (name === "title") {
      socialAllData.Employment.newEmployeeTurnover.employeeRegionWise[index][name] = value;
    } else if (numberRegex.test(value) || value === "") {
      if (name === "newHireEmployeRatio" || name === "newHireEmployeePercentage") {
        if (value <= 100) {
          socialAllData.Employment.newEmployeeTurnover.employeeRegionWise[index][name] = value;
        }
      } else {
        socialAllData.Employment.newEmployeeTurnover.employeeRegionWise[index][name] = value;
      }
    } else {
      return;
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const addNewRowEmployeeRegionWise = () => {
    let productRowObj = {
      title: "",
      newHireEmployeCount: "",
      newHireEmployeePercentage: "",
      newHireEmployeRatio: ""
    };
    socialAllData.Employment.newEmployeeTurnover.employeeRegionWise.push(productRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeTurnoverGenderWise = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "newHireEmployeRatio" || name === "newHireEmployeePercentage") {
        if (value <= 100) {
          socialAllData.Employment.newEmployeeTurnover.employeeTurnoverGenderWise[index][name] = value;
        }
      } else {
        socialAllData.Employment.newEmployeeTurnover.employeeTurnoverGenderWise[index][name] = value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeTurnoverAgeWise = (e, index) => {
    const { name, value } = e.target;
    if (numberRegex.test(value) || value === "") {
      if (name === "newHireEmployeRatio" || name === "newHireEmployeePercentage") {
        if (value <= 100) {
          socialAllData.Employment.newEmployeeTurnover.employeeTurnoverAgeWise[index][name] = value;
        }
      } else {
        socialAllData.Employment.newEmployeeTurnover.employeeTurnoverAgeWise[index][name] = value;
      }
    }
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeTurnOverRegionWise = (e, index) => {
    const { name, value } = e.target;
    if (name === "title") {
      socialAllData.Employment.newEmployeeTurnover.employeeTurnOverRegionWise[index][name] = value;
    } else {
      if (numberRegex.test(e.target.value) || e.target.value === "") {
        if (name === "newHireEmployeRatio" || name === "newHireEmployeePercentage") {
          if (value <= 100) {
            socialAllData.Employment.newEmployeeTurnover.employeeTurnOverRegionWise[index][e.target.name] = value;
          }
        } else {
          socialAllData.Employment.newEmployeeTurnover.employeeTurnOverRegionWise[index][e.target.name] = value;
        }
      }
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const addNewRowEmployeeTurnOverRegionWise = () => {
    let productRowObj = {
      newHireEmployeCount: "",
      newHireEmployeePercentage: "",
      newHireEmployeRatio: ""
    };
    socialAllData.Employment.newEmployeeTurnover.employeeTurnOverRegionWise.push(productRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeByLocationOperation = (e, index) => {
    const { name, value } = e.target;
    if (name === "location" || name === "otherBenefits") {
      socialAllData.Employment.benefitsProvided.employeeByLocationOperation[index][name] = value;
    } else {
      if (numberRegex.test(e.target.value) || e.target.value === "") {
        if (
          name === "insuPercentage" ||
          name === "ownerShipPercent" ||
          name === "provisionPercent" ||
          name === "coveragePercent"
        ) {
          if (value <= 100) {
            socialAllData.Employment.benefitsProvided.employeeByLocationOperation[index][e.target.name] = value;
          }
        } else {
          socialAllData.Employment.benefitsProvided.employeeByLocationOperation[index][e.target.name] = value;
        }
      }
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const addNewRowEmployeeByLocationOperation = () => {
    let productRowObj = {
      location: "Location",
      numberOfFTEWithLifeInsurance: "",
      insuPercentage: "",
      numberOfFTEWithHealthInsurance: "",
      disabilityCoverage: "",
      coveragePercent: "",
      retirementProvision: "",
      provisionPercent: "",
      stockOwnership: "",
      ownerShipPercent: "",
      otherBenefits: ""
    };
    socialAllData.Employment.benefitsProvided.employeeByLocationOperation.push(productRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeBenefitsKind = (e, index) => {
    const { name, value } = e.target;
    if (name === "location" || name === "workTtime" || name === "other") {
      socialAllData.Employment.employeeBenefitsKind[index][name] = value;
    } else {
      if (numberRegex.test(e.target.value) || e.target.value === "") {
        socialAllData.Employment.employeeBenefitsKind[index][e.target.name] = value;
      }
    }

    dispatch(setPageWiseData(socialAllData));
  };

  const addNewRowEmployeeBenefitsKind = () => {
    let productRowObj = {
      location: "Location",
      sportsFacilities: "",
      careFacilities: "",
      workTtime: "",
      other: ""
    };
    socialAllData.Employment.employeeBenefitsKind.push(productRowObj);
    dispatch(setPageWiseData(socialAllData));
  };

  const handleEmployeeParentalLeave = (e, index) => {
    if (numberRegex.test(e.target.value) || e.target.value === "") {
      socialAllData.Employment.parentalLeave.employeeParentalLeave[index][e.target.name] = e.target.value;

      dispatch(setPageWiseData(socialAllData));
    }
  };

  // const deleteRow = (rowIndex, name) => {
  //   if (rowIndex > 0) {
  //     let tempArray = [...socialAllData.Employment[name]];
  //     tempArray.splice(rowIndex, 1);
  //     socialAllData.Employment[name] = tempArray;
  //     dispatch(setPageWiseData(socialAllData));
  //   }
  // };

  const deleteRow = (parentKey, arrayKey, rowIndex) => {
    const parentObject = socialAllData.Employment?.[parentKey];
    const dataArray = parentObject?.[arrayKey];
    if (Array.isArray(dataArray)) {
      if (rowIndex >= 0 && rowIndex < dataArray.length) {
        const updatedArray = dataArray.filter((_, index) => index !== rowIndex);

        const updatedParentObject = {
          ...parentObject,
          [arrayKey]: updatedArray
        };

        const updatedData = {
          ...socialAllData,
          Employment: {
            ...socialAllData.Employment,
            [parentKey]: updatedParentObject
          }
        };

        dispatch(setPageWiseData(updatedData));
      }
    }
  };

  const getPopupContainer = (trigger) => {
    return document.querySelector(".esg-table-content") || document.body;
  };

  const handleAccordionActivation = (index) => {
    setaccordianActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const onFocus = (index) => {
    setaccordianActiveIndex(index);
  };

  return (
    <div className="esg-table-content">
      <div className="form-bullet-points-div ">
        <Accordion title={"I. New Employee and Employee Turnover"} index={0}>
          <table className="form-table mb-4 mt-1">
            <thead>
              <tr>
                <th className="sm-column" scope="col"></th>
                <th scope="col">Number of new employee hires</th>
                <th scope="col">New Employee hire ratio %</th>
              </tr>
            </thead>
            <tbody>
              {socialAllData?.Employment?.newEmployeeTurnover?.newHireEmployee?.map((item, index) => (
                <tr key={index + item}>
                  <td>{item.title}</td>
                  <td>
                    <input
                      type="text"
                      name="newHireEmployeCount"
                      value={item.newHireEmployeCount}
                      onChange={(e) => handleNewHireEmployee(e, index)}
                      className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                      readOnly={editMode && !formEditable}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="newHireEmployeRatio"
                      value={item.newHireEmployeRatio}
                      onChange={(e) => handleNewHireEmployee(e, index)}
                      className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                      readOnly={editMode && !formEditable}
                    ></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <table className="form-table mb-4">
            <thead>
              <tr>
                <th className="sm-column" scope="col"></th>
                <th scope="col">Number of employees turnover</th>
                <th scope="col">Turnover ratio %</th>
              </tr>
            </thead>
            <tbody>
              {socialAllData?.Employment?.newEmployeeTurnover?.employeesTurnover?.map((item, index) => (
                <tr key={index + item}>
                  <td>{item.title}</td>
                  <td>
                    <input
                      type="text"
                      name="newHireEmployeTurnOver"
                      value={item.newHireEmployeTurnOver}
                      onChange={(e) => handleEmployeesTurnover(e, index)}
                      className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                      readOnly={editMode && !formEditable}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="newHireEmployeTurnOverRatio"
                      value={item.newHireEmployeTurnOverRatio}
                      onChange={(e) => handleEmployeesTurnover(e, index)}
                      className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                      readOnly={editMode && !formEditable}
                    ></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <table className="form-table mb-4">
            <thead>
              <tr>
                <th className="sm-column" scope="col"></th>
                <th scope="col">Number of Voluntary turnover</th>
                <th scope="col">Voluntary ratio %</th>
              </tr>
            </thead>
            <tbody>
              {socialAllData?.Employment?.newEmployeeTurnover?.voluntaryTurnover?.map((item, index) => (
                <tr key={item + index}>
                  <td>{item.title}</td>
                  <td>
                    <input
                      type="text"
                      name="newHireEmployeTurnOver"
                      value={item.newHireEmployeTurnOver}
                      onChange={(e) => handleVoluntaryTurnover(e, index)}
                      className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                      readOnly={editMode && !formEditable}
                    ></input>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="newHireEmployeTurnOverRatio"
                      value={item.newHireEmployeTurnOverRatio}
                      onChange={(e) => handleVoluntaryTurnover(e, index)}
                      className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                      readOnly={editMode && !formEditable}
                    ></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <p className="form-bullet-points">New employee hire by gender, age group, region</p>

          <div>
            <table className="form-table mb-4">
              <thead>
                <tr>
                  <th scope="col">Gender</th>
                  <th scope="col">Number of Employee</th>
                  <th scope="col">% of total new hire</th>
                  <th scope="col">New hire rate % (new hire/ total number of employees)</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.newEmployeeTurnover?.employeeGenderWise?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeCount"
                        value={item.newHireEmployeCount}
                        onChange={(e) => handleEmployeeGenderWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeePercentage"
                        value={item.newHireEmployeePercentage}
                        onChange={(e) => handleEmployeeGenderWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeRatio"
                        value={item.newHireEmployeRatio}
                        onChange={(e) => handleEmployeeGenderWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <table className="form-table mb-4">
              <thead>
                <tr>
                  <th scope="col">Age group</th>
                  <th scope="col">Number of Employee</th>
                  <th scope="col">% of total new hire</th>
                  <th scope="col">New hire rate % (new hire/ total number of employees)</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.newEmployeeTurnover?.employeeAgeWise?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeCount"
                        value={item.newHireEmployeCount}
                        onChange={(e) => handleEmployeeAgeWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeePercentage"
                        value={item.newHireEmployeePercentage}
                        onChange={(e) => handleEmployeeAgeWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeRatio"
                        value={item.newHireEmployeRatio}
                        onChange={(e) => handleEmployeeAgeWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Region</th>
                  <th scope="col">Number of Employee</th>
                  <th scope="col">% of total new hire</th>
                  <th scope="col">New hire rate % (new hire/ total number of employees)</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.newEmployeeTurnover?.employeeRegionWise?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <Select
                        className="w-100"
                        value={item.title ? item.title : "Region"}
                        onChange={(value) => handleEmployeeRegionWise({ target: { value, name: "title" } }, index)}
                        disabled={editMode && !formEditable}
                        getPopupContainer={getPopupContainer}
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeCount"
                        value={item.newHireEmployeCount}
                        onChange={(e) => handleEmployeeRegionWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeePercentage"
                        value={item.newHireEmployeePercentage}
                        onChange={(e) => handleEmployeeRegionWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeRatio"
                        value={item.newHireEmployeRatio}
                        onChange={(e) => handleEmployeeRegionWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          // onClick={() => deleteRow(index, "employeeRegionWise")}
                          onClick={() => deleteRow("newEmployeeTurnover", "employeeRegionWise", index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row" onClick={() => addNewRowEmployeeRegionWise()}>
            <p>+ Add New Region</p>
          </button>

          <p className="form-bullet-points">Employees Turnover by gender, age group, region</p>
          <div>
            <table className="form-table mb-4">
              <thead>
                <tr>
                  <th scope="col">Gender</th>
                  <th scope="col">Number of Employee</th>
                  <th scope="col">% of total new hire</th>
                  <th scope="col">New hire rate % (new hire/ total number of employees)</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.newEmployeeTurnover?.employeeTurnoverGenderWise?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeCount"
                        value={item.newHireEmployeCount}
                        onChange={(e) => handleEmployeeTurnoverGenderWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeePercentage"
                        value={item.newHireEmployeePercentage}
                        onChange={(e) => handleEmployeeTurnoverGenderWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeRatio"
                        value={item.newHireEmployeRatio}
                        onChange={(e) => handleEmployeeTurnoverGenderWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <table className="form-table mb-4">
              <thead>
                <tr>
                  <th scope="col">Age group</th>
                  <th scope="col">Number of Employee</th>
                  <th scope="col">% of total new hire</th>
                  <th scope="col">New hire rate % (new hire/ total number of employees)</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.newEmployeeTurnover?.employeeTurnoverAgeWise?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeCount"
                        value={item.newHireEmployeCount}
                        onChange={(e) => handleEmployeeTurnoverAgeWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeePercentage"
                        value={item.newHireEmployeePercentage}
                        onChange={(e) => handleEmployeeTurnoverAgeWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeRatio"
                        value={item.newHireEmployeRatio}
                        onChange={(e) => handleEmployeeTurnoverAgeWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div>
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Region</th>
                  <th scope="col">Number of Employee</th>
                  <th scope="col">% of total new hire</th>
                  <th scope="col">New hire rate % (new hire/ total number of employees)</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.newEmployeeTurnover?.employeeTurnOverRegionWise?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <Select
                        className="w-100"
                        value={item.title ? item.title : "Region"}
                        onChange={(value) =>
                          handleEmployeeTurnOverRegionWise({ target: { value, name: "title" } }, index)
                        }
                        disabled={editMode && !formEditable}
                        getPopupContainer={getPopupContainer}
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeCount"
                        value={item.newHireEmployeCount}
                        onChange={(e) => handleEmployeeTurnOverRegionWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeePercentage"
                        value={item.newHireEmployeePercentage}
                        onChange={(e) => handleEmployeeTurnOverRegionWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="newHireEmployeRatio"
                        value={item.newHireEmployeRatio}
                        onChange={(e) => handleEmployeeTurnOverRegionWise(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("newEmployeeTurnover","employeeTurnOverRegionWise" , index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row" onClick={() => addNewRowEmployeeTurnOverRegionWise()}>
            <p>+ Add New Region</p>
          </button>
        </Accordion>
      </div>

      <div className="form-bullet-points-div mt-3">
        <Accordion
          //  ref={accordionRef}
          title={
            "II. Benefits provided to full-time employees that are not provide to temporary or part-time employees"
          }
          index={1}
          activeIndex={accordianActiveIndex}
          onActivate={handleAccordionActivation}
        >
          <div className="table-scrollable">
            <table className="form-table form-table my-1 w-auto">
              <thead>
                <tr>
                  <th style={{ minWidth: "250px" }} scope="col">
                    By location of operation
                  </th>
                  <th style={{ minWidth: "200px" }} scope="col">
                    Number of FTE with life insurance
                  </th>
                  <th style={{ minWidth: "150px" }} scope="col">
                    % of total
                  </th>
                  <th style={{ minWidth: "200px" }} scope="col">
                    Number of FTE with health insurance
                  </th>
                  <th style={{ minWidth: "250px" }}>number of FTE with disability and invalidity coverage</th>
                  <th style={{ minWidth: "150px" }}>% of total</th>
                  <th style={{ minWidth: "200px" }}>Number of FTE with retirement provision</th>
                  <th style={{ minWidth: "150px" }} scope="col">
                    % of total
                  </th>
                  <th style={{ minWidth: "200px" }}>Number of FTE with stock ownership</th>
                  <th style={{ minWidth: "150px" }}>% of total</th>
                  <th>Other benefits</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.benefitsProvided?.employeeByLocationOperation?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <Select
                        className="w-100"
                        onFocus={() => onFocus(1)}
                        value={item.location ? item.location : "Location"}
                        onChange={(value) =>
                          handleEmployeeByLocationOperation({ target: { value, name: "location" } }, index)
                        }
                        disabled={editMode && !formEditable}
                        getPopupContainer={getPopupContainer}
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="numberOfFTEWithLifeInsurance"
                        value={item.numberOfFTEWithLifeInsurance}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="insuPercentage"
                        value={item.insuPercentage}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="numberOfFTEWithHealthInsurance"
                        value={item.numberOfFTEWithHealthInsurance}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="disabilityCoverage"
                        value={item.disabilityCoverage}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="coveragePercent"
                        value={item.coveragePercent}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="retirementProvision"
                        value={item.retirementProvision}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="provisionPercent"
                        value={item.provisionPercent}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="stockOwnership"
                        value={item.stockOwnership}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="ownerShipPercent"
                        value={item.ownerShipPercent}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <textarea
                        rows={2}
                        name="otherBenefits"
                        value={item.otherBenefits}
                        onChange={(e) => handleEmployeeByLocationOperation(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></textarea>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow("benefitsProvided", "employeeByLocationOperation", index)}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button
            className="btn btn-outline-success add-new-row"
            onClick={() => addNewRowEmployeeByLocationOperation()}
          >
            <p>+ Add new location</p>
          </button>

          <div className="mt-4">
            <table className="form-table">
              <thead>
                <tr>
                  <th scope="col">Benefits (in kind)</th>
                  <th scope="col">Number of sports facilities</th>
                  <th scope="col">Number of child day care facilities</th>
                  <th scope="col">Free meals during work time (Yes/No)</th>
                  <th>Other</th>
                  <th className="col-md-1" scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.employeeBenefitsKind?.map((item, index) => (
                  <tr key={item + index}>
                    <td>
                      <Select
                        className="w-100"
                        value={item.location ? item.location : "Location"}
                        onChange={(value) => handleEmployeeBenefitsKind({ target: { value, name: "location" } }, index)}
                        disabled={editMode && !formEditable}
                        getPopupContainer={getPopupContainer}
                      >
                        {user?.facilities?.map((item, ind) => (
                          <Select.Option key={ind} value={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="sportsFacilities"
                        value={item.sportsFacilities}
                        onChange={(e) => handleEmployeeBenefitsKind(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="careFacilities"
                        value={item.careFacilities}
                        onChange={(e) => handleEmployeeBenefitsKind(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <Select
                        className="w-100"
                        value={item.workTtime ? item.workTtime : "No"}
                        onChange={(value) =>
                          handleEmployeeBenefitsKind({ target: { value, name: "workTtime" } }, index)
                        }
                        disabled={editMode && !formEditable}
                        getPopupContainer={getPopupContainer}
                      >
                        {["Yes", "No"].map((item, ind) => (
                          <Select.Option key={ind} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <textarea
                        rows={2}
                        name="other"
                        value={item.other}
                        onChange={(e) => handleEmployeeBenefitsKind(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></textarea>
                    </td>
                    <td className="small-width-columns">
                      {index !== 0 && (
                        <img
                          src="/images/icons/delete-icon.svg"
                          alt=""
                          onClick={() => deleteRow(index, "employeeBenefitsKind")}
                          className="cursor-pointer"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn btn-outline-success add-new-row" onClick={() => addNewRowEmployeeBenefitsKind()}>
            <p>+ Add new location</p>
          </button>
        </Accordion>
      </div>


      <div className="form-bullet-points-div mt-3 ">
        <Accordion title={"III. Parental leave"} index={1} onActivate={""}>
          <div className="table-scrollable">
            <table className="form-table form-table my-1 w-auto">
              <thead>
                <tr>
                  <th className="col-md-1" scope="col"></th>
                  <th scope="col">Total number of employees entitled to parental leave</th>
                  <th scope="col">Total number of employees who took parental leave</th>
                  <th scope="col">
                    Total number of employees who returned to work in the reporting period after parental leave ended
                  </th>
                  <th>Return to work ratio</th>
                  <th>Retention ratio</th>
                </tr>
              </thead>
              <tbody>
                {socialAllData?.Employment?.parentalLeave?.employeeParentalLeave?.map((item, index) => (
                  <tr key={item + index}>
                    <td>{item.title}</td>
                    <td>
                      <input
                        type="text"
                        onFocus={() => onFocus(2)}
                        name="totalNumberOfEmployeesEntitledPL"
                        value={item.totalNumberOfEmployeesEntitledPL}
                        onChange={(e) => handleEmployeeParentalLeave(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="totalEmployeewhoTookPL"
                        value={item.totalEmployeewhoTookPL}
                        onChange={(e) => handleEmployeeParentalLeave(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="totalEmployeereturnToWorkAfterPL"
                        value={item.totalEmployeereturnToWorkAfterPL}
                        onChange={(e) => handleEmployeeParentalLeave(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="workRatio"
                        value={item.workRatio}
                        onChange={(e) => handleEmployeeParentalLeave(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="retentionRatio"
                        value={item.retentionRatio}
                        onChange={(e) => handleEmployeeParentalLeave(e, index)}
                        className={`no-style-input ${formEditable ? "border border-2 rounded-1 p-1" : ""} `}
                        readOnly={editMode && !formEditable}
                      ></input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default Employment;
