import React from "react";
import { Box, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CustomTooltip = ({ item }) => {
  // Function to format department and facility lists
  const formatList = (list, type) => {
    const maxValueShowing = type === "facility" ? 1 : 3;
    if (list.length > maxValueShowing) {
      return `${list.slice(0, maxValueShowing).join(", ")} +${list.length - maxValueShowing}`;
    }
    return list.join(", ");
  };

  return (
    <Box
      style={{
        width: "224px",
        // height: "130px", // Increased height to accommodate padding
        padding: "14px 16px",
        paddingBottom: "16px", // Additional padding at the bottom for space
        borderRadius: "16px", // Increased border radius
        color: "#ffffff", // Text color
        opacity: 1,
        lineHeight: "16px",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: 400,
          fontSize: "14px",
          fontFamily: "Poppins",
          lineHeight: "16px",
          paddingBottom: "8px"
        }}
      >
        <InfoOutlinedIcon sx={{ mr: 0.5 }} /> {item.name}
      </Typography>

      {/* Display department list */}
      <Typography
        variant="body2"
        sx={{
          paddingLeft: "15px",
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Dept:</span> {formatList(item.department, "department")}
      </Typography>

      {/* Display facility list */}
      <Typography
        variant="body2"
        sx={{
          paddingLeft: "15px",
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Facility:</span> {formatList(item.facility.map((facility) => facility.facilityName), "facility")}
      </Typography>

      <Typography
        variant="body2"
        style={{
          paddingTop: "12px",
          paddingLeft: "15px",
          fontWeight: "bolder",
          fontFamily: "Poppins",
          fontSize: "13px",
          lineHeight: "18px",
        }}
      >
        {item.role === "maker" ? "Maker" : "Approver"}
      </Typography>
    </Box>
  );
};

export default CustomTooltip;
