import {
  adminAssignedEmptyScreenMessages,
  approverAssignedEmptyScreenMessages,
  makerAssignedEmptyScreenMessages
} from "../../../helper/constant";

const EmptyState = ({ dotFilter, role }) => {
  const emptyScreenMessage =
    role === "Admin"
      ? adminAssignedEmptyScreenMessages
      : role === "maker"
      ? makerAssignedEmptyScreenMessages
      : approverAssignedEmptyScreenMessages;

  const activeMessage =
    emptyScreenMessage.find((filter) => dotFilter[filter.key])?.message || "No assigned questions yet!";

  const activeSubMessage =
    emptyScreenMessage.find((filter) => dotFilter[filter.key])?.subMessage ||
    "Contributors have not been assigned any questions.";

  return (
    <div className="empty-assigned-state" style={{ height: "50vh" }}>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
          <circle cx="21.0003" cy="16.3333" r="9.33333" stroke="#E2E2EA" strokeWidth="2.4" />
          <path
            d="M4.66699 36.1667C4.66699 34.2337 6.234 32.6667 8.16699 32.6667H33.8337C35.7667 32.6667 37.3337 34.2338 37.3337 36.1667V49.0001H4.66699V36.1667Z"
            stroke="#E2E2EA"
            strokeWidth="2.4"
            strokeLinejoin="round"
          />
          <path d="M44.334 17.5V31.5" stroke="#E2E2EA" strokeWidth="2.4" strokeLinejoin="round" />
          <path d="M37.334 24.5H51.334" stroke="#E2E2EA" strokeWidth="2.4" strokeLinejoin="round" />
        </svg>
      </div>
      <p className="heading-text">{activeMessage}</p>
      <p className="sub-heading-text">{activeSubMessage}</p>
    </div>
  );
};

export default EmptyState;
