import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Input, Popover, Select } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../components/Loaders/ButtonLoader";
import { useSnackbar } from "../../../components/snackbar/SnackbarContext";
import { deepCopyWithUndefined } from "../../../helper/genericFuntions";
import { useLazyGetCategoryTableDataQuery, usePostCalculateEmissionRowMutation } from "../../../state/api";
import { setTabWiseValueGlobal } from "../../../state/multiplCategorySlice";
import "./DataTable.scss";
import { fetchData } from "../../../helper/ErrorHandler";
import { apiUrl } from "../../../components/ApiUrl";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { debounce } from "lodash";

const keysToExclude = ["year", "month", "_id", "facility"];

const DataTable = ({ key, receivedDataId, facData, name }) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  const [calculateEmissionData, resultCalculateEmission] = usePostCalculateEmissionRowMutation({
    fixedCacheKey: "Emissionresult"
  });
  const [getCategoryTableInitial, resultsInitial] = useLazyGetCategoryTableDataQuery();

  const { tabWiseValueGlobal } = useSelector((state) => state.category);

  const inputRefs = useRef([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [userFileReader, setUserFileReader] = useState({});
  const [isFileUploading, setIsFileLoading] = useState(false);

  useEffect(() => {
    if (!(receivedDataId in tabWiseValueGlobal)) {
      getCategoryTableInitial(receivedDataId, true);

      let tabMeta = {
        tableHeaders: null,
        allMandatoryFields: null,
        isMandatoryFilled: { 0: false },
        rowDataArr: []
      };

      if (resultsInitial.data) {
        tabMeta["tableHeaders"] = [...resultsInitial.data.data.headers].filter(
          (obj) => !keysToExclude.includes(obj.fieldName)
        );
        tabMeta["allMandatoryFields"] = tabMeta["tableHeaders"].filter((x) => x.isMandatory);
        tabMeta.rowDataArr.push(createNewRow(tabMeta.tableHeaders));
        updateTabWiseValue({ ...tabWiseValueGlobal, [receivedDataId]: tabMeta });
      }
    }
  }, [resultsInitial.data]);

  const [isLoading, setIsLoading] = useState({ 0: false });

  useEffect(() => {
    if (!resultCalculateEmission.isLoading) {
      setIsLoading({ 0: false });
    }
  }, [resultCalculateEmission.isLoading]);

  useEffect(() => {
    let allTabValue = deepCopyWithUndefined(tabWiseValueGlobal);

    if (name === "Air Emission") {
      allTabValue[receivedDataId]?.rowDataArr?.map((row, key) => {
        row["fuelUnit"] = "64fc026c5228cac66a909add";
      });
      updateTabWiseValue(allTabValue);
    }
  }, [tabWiseValueGlobal[receivedDataId]?.rowDataArr?.length]);

  // const checkRowMandatoryFilled = (row, rowIndex) => {
  //   //This function is used to check if mandatory fields for a row is filled
  //   const tabData = tabWiseValueGlobal[receivedDataId];
  //   const mandatoryFields = tabData.allMandatoryFields;
  //   let check = true;
  //   let emptyMandatoryFields = mandatoryFields.filter((x) => !row[x.fieldName]);
  //   for (let mandatoryField of emptyMandatoryFields) {
  //     if (!mandatoryField?.dependentHeaders?.length) {
  //       check = false;
  //     } else {
  //       const dropdownValues = getDropDownOptions(rowIndex, mandatoryField);
  //       if (dropdownValues) check = false;
  //     }
  //     if (check === false) break;
  //   }
  //   return check;
  // };

  const calculateData = async (rowIndex, allTabVal) => {
    if (name === "Air Emission") {
      return;
    }
    try {
      setIsLoading((previous) => {
        let newState = { ...previous, [rowIndex]: true };
        return newState;
      });

      let newTabVal = deepCopyWithUndefined(allTabVal);
      const rowObj = newTabVal[receivedDataId].rowDataArr[rowIndex];

      let res = await calculateEmissionData({ templete: receivedDataId, data: rowObj });
      if (res?.data?.success) {
        openSnackbar(res.data.message, "success");
        rowObj.totalEmission = res.data.data.totalEmission;
      } else {
        delete rowObj.totalEmission;
        console.log("warning while calculating", res?.error?.data.message, rowObj);
        openSnackbar(res?.error?.data.message, "warning");
        return;
      }
      updateTabWiseValue(newTabVal);
      setIsLoading((previous) => {
        let newState = { ...previous };
        delete newState[rowIndex];
        return newState;
      });
    } catch (err) {
      console.log("erroe while calculating", err.message);
      openSnackbar(err.message, "error");
    }
  };

  const checkRowMandatoryFilled = (row, rowIndex) => {
    //This function is used to check if mandatory fields for a row is filled
    const tabData = tabWiseValueGlobal[receivedDataId];
    const mandatoryFields = tabData.allMandatoryFields;
    let checkArr = [];
    mandatoryFields.forEach((item) => {
      if (!row[item.fieldName]) {
        checkArr.push(false);
      } else {
        checkArr.push(true);
      }
    });

    const result = checkArr.includes(false);
    return !result;
  };

  const debouncedCalculateData = useCallback(
    debounce((rowIndex, allTabValue) => {
      calculateData(rowIndex, allTabValue);
    }, 1000),
    []
  );

  const handleRowChange = (fieldName, value, rowIndex) => {
    // This function handles input box changes
    let numberRegex = /^\d+$/;

    let allTabValue = deepCopyWithUndefined(tabWiseValueGlobal);
    let rowData = allTabValue[receivedDataId].rowDataArr[rowIndex];

    if (fieldName === "bill") {
      rowData["bill"] = value;
      updateTabWiseValue(allTabValue);

      return;
    }

    if (name === "Air Emission") {
      rowData["divertedQuantityUnit"] = "64fc026c5228cac66a909ade";
      if ((numberRegex.test(value) && fieldName === "fuelConsumed") || value === "") {
        rowData[fieldName] = value;
        if (fieldName === "fuelConsumed") {
          rowData.totalEmission = value;
        }
      }

      allTabValue[receivedDataId].isMandatoryFilled[rowIndex] = checkRowMandatoryFilled(rowData, rowIndex);
      updateTabWiseValue(allTabValue);
      return;
    }

    const header = allTabValue[receivedDataId].tableHeaders.find((x) => x.fieldName === fieldName);
    if (allTabValue[receivedDataId].allMandatoryFields.map((x) => x._id).includes(header._id))
      delete rowData.totalEmission;
    if ((header.dataType === "number" || header.dataType === "integer") && parseFloat(value) < 0) {
      openSnackbar("Cannot have negative values", "warning");
      rowData[fieldName] = "";
    } else {
      if (numberRegex.test(value) || value === "") {
        rowData[fieldName] = value;
      } else if (
        fieldName === "supplierName" ||
        fieldName === "companyName" ||
        fieldName === "employeeGroup" ||
        fieldName === "name" ||
        fieldName === "activityName" ||
        fieldName === "activityType" ||
        fieldName === "activityCategory" ||
        fieldName === "purchaserName"
      ) {
        rowData[fieldName] = value;
      }
    }
    allTabValue[receivedDataId].isMandatoryFilled[rowIndex] = checkRowMandatoryFilled(rowData, rowIndex);
    updateTabWiseValue(allTabValue);

    const tabData = tabWiseValueGlobal[receivedDataId];
    const mandatoryFields = tabData.allMandatoryFields;

    if (
      mandatoryFields.some((item) => item.fieldName === fieldName) &&
      allTabValue[receivedDataId].isMandatoryFilled[rowIndex]
    ) {
      debouncedCalculateData(rowIndex, allTabValue);
    } else if (
      mandatoryFields.some((item) => item.fieldName === fieldName) &&
      allTabValue[receivedDataId].isMandatoryFilled[rowIndex] &&
      !rowData["totalEmission"]
    ) {
      debouncedCalculateData(rowIndex, allTabValue);
    }
  };

  const rowDropdownChange = (fieldName, value, rowIndex) => {
    // This function handles dropdown value changes
    if (tabWiseValueGlobal && tabWiseValueGlobal[receivedDataId]) {
      let allTabData = deepCopyWithUndefined(tabWiseValueGlobal);
      const headers = allTabData[receivedDataId].tableHeaders;
      let row = allTabData[receivedDataId].rowDataArr[rowIndex];
      if (row && row["divertedQuantityUnit"]) {
        row["divertedQuantityUnit"] = "64fc026c5228cac66a909ade";
      }
      updateRowValues(headers, row, fieldName, value);
      if (name === "Air Emission") {
        row["totalEmission"] = row.fuelConsumed;
      }
      allTabData[receivedDataId].isMandatoryFilled[rowIndex] = checkRowMandatoryFilled(row, rowIndex);
      updateTabWiseValue(allTabData);

      const tabData = tabWiseValueGlobal[receivedDataId];
      const mandatoryFields = tabData.allMandatoryFields;

      if (
        mandatoryFields.some((item) => item.fieldName === fieldName) &&
        allTabData[receivedDataId].isMandatoryFilled[rowIndex]
      ) {
        calculateData(rowIndex, allTabData);
      } else if (
        mandatoryFields.some((item) => item.fieldName === fieldName) &&
        allTabData[receivedDataId].isMandatoryFilled[rowIndex] &&
        !row["totalEmission"]
      ) {
        calculateData(rowIndex, allTabData);
      }
    }
  };

  const updateRowValues = (headers, row, fieldName, value) => {
    let lengthOne = false;
    //This function is used to update all row values if a dropdown value changes. Column Dependency handling
    const header = headers.find((x) => x.fieldName === fieldName);

    const tabData = tabWiseValueGlobal[receivedDataId];
    const mandatoryFields = tabData.allMandatoryFields;
    if (mandatoryFields.some((item) => item.fieldName === fieldName)) {
      delete row.totalEmission;
    }
    row[fieldName] = value;
    if (
      value &&
      headers.find((x) => x.fieldName === "fuelType")?.dependentHeaders &&
      header._id === headers.find((x) => x.fieldName === "fuelType")?.dependentHeaders[0]
    ) {
      let fuelTypeValues = headers.find((x) => x.fieldName === "fuelType")?.values;
      let arrayVal = fuelTypeValues.find((item) => {
        return item.dependentValues.find((y) => y.value === value);
      });

      arrayVal = arrayVal ? arrayVal.values : [];

      if (arrayVal.length === 1) {
        lengthOne = true;
        row["fuelType"] = arrayVal[0];
      } else {
        lengthOne = false;
      }
      return row;
    }

    const dependencyHeaders = headers.filter((x) => x?.dependentHeaders?.includes(header._id));
    if (dependencyHeaders.length) {
      dependencyHeaders.forEach((x) => {
        if ((x.fieldName === "fuelType" && !lengthOne) || !(x.fieldName === "fuelType"))
          updateRowValues(headers, row, x.fieldName, undefined);
      });
    } else return row;
  };

  const getDropDownOptions = (rowIndex, fieldName) => {
    //This function is to get dropDown Values for select dropdowns
    const header = tabWiseValueGlobal[receivedDataId].tableHeaders.find((x) => x.fieldName === fieldName);
    let actualValues = [];
    if (header?.dependentHeaders?.length) {
      const rowData = tabWiseValueGlobal[receivedDataId].rowDataArr[rowIndex];
      const dependentHeaderValues = header.dependentHeaders.reduce((acc, item) => {
        acc[item] = rowData[tabWiseValueGlobal[receivedDataId].tableHeaders.find((x) => x._id === item)?.fieldName];
        return acc;
      }, {});

      actualValues = header.values.find((value) => {
        const isMatch = Object.keys(dependentHeaderValues).every((key) => {
          return value.dependentValues.some(
            (dv) => dv.dependentField === key && dv.value === dependentHeaderValues[key]
          );
        });
        return isMatch;
      });
      if (actualValues) {
        actualValues = actualValues.values;
      } else return [];
    } else actualValues = header?.values;

    return actualValues?.map((val) => ({
      value: val._id || val,
      label: val._id ? (val.symbol ? val.symbol : val.name) : val
    }));
  };

  const createNewRow = (headers) => {
    //This function is used to create a new row based on headers
    const rowObject = headers.reduce((acc, item) => {
      acc[item.fieldName] = item.type === "input" ? "" : undefined;
      return acc;
    }, {});
    return rowObject;
  };

  const addNewRow = (e) => {
    let allTabVal = deepCopyWithUndefined(tabWiseValueGlobal);
    allTabVal[receivedDataId].rowDataArr.push(createNewRow(allTabVal[receivedDataId].tableHeaders));
    allTabVal[receivedDataId].isMandatoryFilled[allTabVal[receivedDataId].rowDataArr.length - 1] = false;
    updateTabWiseValue(allTabVal);
  };

  const deleteRow = (rowIndex) => {
    if (rowIndex > 0) {
      let allTabVal = deepCopyWithUndefined(tabWiseValueGlobal);
      let mandatoryCheckObject = allTabVal[receivedDataId].isMandatoryFilled;
      delete mandatoryCheckObject[rowIndex];
      allTabVal[receivedDataId].isMandatoryFilled = Object.keys(mandatoryCheckObject).reduce((acc, item, itemIndex) => {
        acc[itemIndex] = mandatoryCheckObject[item];
        return acc;
      }, {});
      allTabVal[receivedDataId].rowDataArr.splice(rowIndex, 1);
      updateTabWiseValue(allTabVal);
    } else {
      openSnackbar("Cannot delete first row", "warning");
    }
  };

  const updateTabWiseValue = (newTabWiseValue) => {
    dispatch(setTabWiseValueGlobal(newTabWiseValue));
  };

  const uploadFileInitiate = (index) => {
    let allTabValue = deepCopyWithUndefined(tabWiseValueGlobal);
    let rowData = allTabValue[receivedDataId].rowDataArr[index];

    if (rowData["bill"]) {
      return;
    }
    if (inputRefs.current[index]) {
      inputRefs.current[index].click();
    }
  };

  const uploadFileInitiateNew = (index) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].click();
    }
  };

  const handleFileChange = (key, event) => {
    try {
      const file = event.target.files;

      if (file && file.length > 0) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setUploadFile(file[0]);
          setUserFileReader({ index: key, file: reader.result });
        };
        reader.readAsDataURL(file[0]);
      }
    } catch (error) {
      openSnackbar(error.message, "error");
    }
  };

  const handleFileUpload = async () => {
    setIsFileLoading(true);
    if (uploadFile) {
      const formData = new FormData();
      formData.append("files", uploadFile);

      try {
        let fileReponse = await fetchData(`${apiUrl}/emission/upload/bill`, {
          method: "POST",
          credentials: "include",
          body: formData
        });
        let fileData = await fileReponse.json();
        // setIsFileLoading(false)
        return fileData.data;
      } catch (error) {
        // setIsFileLoading(false)
        throw error;
      }
    }
    setIsFileLoading(false);
  };

  useEffect(() => {
    const updateFileUrl = async () => {
      try {
        let fileUrl = await handleFileUpload();
        handleRowChange("bill", fileUrl, userFileReader.index);
        openSnackbar("File Uploaded", "success");
      } catch (error) {
        const errorMessage = error.response?.data?.message || "An error occurred";
        openSnackbar(errorMessage, "error");
      }
    };

    if (uploadFile) {
      updateFileUrl();
    }
  }, [uploadFile]);

  const downloadFile = (index) => {
    let allTabValue = deepCopyWithUndefined(tabWiseValueGlobal);
    let rowData = allTabValue[receivedDataId].rowDataArr[index];

    let url = rowData["bill"];

    const s3Url = url;
    const link = document.createElement("a");
    link.href = s3Url;
    link.target = "_blank";
    link.setAttribute("download", `FileName.pdf`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const deleteFile = (index) => {
    let allTabValue = deepCopyWithUndefined(tabWiseValueGlobal);
    let rowData = allTabValue[receivedDataId].rowDataArr[index];

    rowData["bill"] = null;
    updateTabWiseValue(allTabValue);
  };

  const getPopupContainer = (trigger) => {
    // Assuming your table has a specific class or id
    return document.querySelector(".table-wrapper") || document.body;
  };

  const popOverContent = (index) => (
    <div className="d-flex gap-2">
      <CustomTooltip
        content={"<p>Upload</p>"}
        showIcon={true}
        id={"facStatus-info"}
        childComponent={
          <div onClick={() => uploadFileInitiateNew(index)} className="cursor-pointer rounded-full hover-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6 6H16C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18H6C3.79086 18 2 16.2091 2 14C2 13.6914 2.03494 13.391 2.10108 13.1025C2.50845 11.3255 4.09943 10 6 10L16 10C17.1046 10 18 10.8954 18 12C18 13.1046 17.1046 14 16 14H6"
                stroke="#7E7E8F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        }
      />
      <CustomTooltip
        content={"<p>Download</p>"}
        showIcon={true}
        id={"facStatus-info"}
        childComponent={
          <div onClick={() => downloadFile(index)} className="cursor-pointer rounded-full hover-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          </div>
        }
      />
      <CustomTooltip
        content={"<p>Delete</p>"}
        showIcon={true}
        id={"facStatus-info"}
        childComponent={
          <div className="cursor-pointer rounded-full hover-icon">
            <img
              src="/images/icons/delete-icon.svg"
              alt=""
              onClick={() => deleteFile(index)}
              className="cursor-pointer"
            />
          </div>
        }
      />
    </div>
  );

  // const isCellVisible = (visibility, rowIndex) => {
  //   if (visibility && visibility.value?.length) {
  //     let dependentField = tabWiseValueGlobal[receivedDataId]?.tableHeaders?.find((x) => x._id === visibility.dependentHeader)?.fieldName;
  //     let dependentValue = tabWiseValueGlobal[receivedDataId]?.rowDataArr[rowIndex]?.[`${dependentField}`];

  //     if (visibility.value.find((x) => x === dependentValue)) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // }

  return (
    <TableContainer className="input-table" key={key}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="white-nowrap">
          <TableRow>
            {tabWiseValueGlobal[receivedDataId]?.tableHeaders?.map((item, key) =>
              name === "Air Emission" && item.label === "Amount" ? (
                <></>
              ) : (
                item.label !== "Unit" &&
                item.label !== "Currency" &&
                item.label !== "Distance Unit" && (
                  <TableCell key={key + "tc"} className="fw-bold text-start">
                    {item.isMandatory ? (
                      <span>
                        {item.label} <sup className="text-danger">*</sup>
                      </span>
                    ) : (
                      item.label
                    )}
                  </TableCell>
                )
              )
            )}
            {name !== "Air Emission" && (
              <TableCell className="stickyTableCellHeader2 calculateCell">
                <p>Emission</p>
                <p>(TCO2e)</p>
              </TableCell>
            )}
            <TableCell className="stickyTableCellHeader">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tabWiseValueGlobal[receivedDataId]?.rowDataArr?.map((row, key) => (
            <TableRow key={key + "tr"}>
              {tabWiseValueGlobal[receivedDataId]?.tableHeaders?.map((subItem, subkey) =>
                name === "Air Emission" && subItem.label === "Amount" ? (
                  <></>
                ) : (
                  subItem.label !== "Unit" &&
                  subItem.label !== "Currency" &&
                  subItem.label !== "Distance Unit" && (
                    <TableCell key={subkey + "stc"} style={{ minWidth: "130px" }}>
                      {
                        // !isCellVisible(subItem.visibility, key) ?
                        //   <></> :
                        <>
                          {subItem.type === "dropdown" ? (
                            <Select
                              showSearch
                              name={subItem.fieldName}
                              size="large"
                              filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                              className={`${
                                !facData.year || !facData.facility
                                  ? "disabled text-start"
                                  : "modalInputTable text-start"
                              }`}
                              // placeholder={`Select ${subItem.label}`}
                              onChange={(value) => rowDropdownChange(subItem.fieldName, value, key)}
                              value={row[subItem?.fieldName]}
                              options={getDropDownOptions(key, subItem.fieldName)}
                              disabled={
                                !facData.year ||
                                !facData.facility ||
                                (subItem?.dependentHeaders?.length &&
                                  !getDropDownOptions(key, subItem.fieldName).length) ||
                                (subItem.fieldName === "fuelType" &&
                                  getDropDownOptions(key, subItem.fieldName).length === 1)
                              }
                              getPopupContainer={getPopupContainer}
                            />
                          ) : subItem.type === "upload" ? (
                            <div
                              className="d-flex justify-content-start cursor-pointer"
                              onClick={() => uploadFileInitiate(key)}
                            >
                              {
                                // isFileUploading ?
                                //   <div className="div-loader">
                                //     <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                //       <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                //     </svg>
                                //   </div> :
                                row["bill"] ? (
                                  <Popover
                                    style={{ width: "100px", height: "100px" }}
                                    trigger="click"
                                    placement="bottom"
                                    title={""}
                                    content={popOverContent(key)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                      />
                                    </svg>
                                  </Popover>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M6 6H16C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18H6C3.79086 18 2 16.2091 2 14C2 13.6914 2.03494 13.391 2.10108 13.1025C2.50845 11.3255 4.09943 10 6 10L16 10C17.1046 10 18 10.8954 18 12C18 13.1046 17.1046 14 16 14H6"
                                      stroke="#7E7E8F"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                )
                              }
                              <input
                                type="file"
                                ref={(el) => (inputRefs.current[key] = el)}
                                style={{ display: "none" }}
                                onChange={(e) => handleFileChange(key, e)}
                              />
                            </div>
                          ) : subItem.label === "Quantity" ||
                            subItem.label === "Weight Transported" ||
                            subItem.label === "Diverted Quantity" ||
                            subItem.label === "Disposed Quantity" ||
                            subItem.label === "Unit" ? (
                            <Input
                              className="hybrid-input"
                              name={subItem.fieldName}
                              value={row[subItem.fieldName]}
                              onChange={(e) => handleRowChange(subItem.fieldName, e.target.value, key)}
                              addonAfter={
                                <Select
                                  showSearch
                                  style={{ height: "40px", width: "125px"}}
                                  name={
                                    getDropDownOptions(key, "fuelUnit")?.length
                                      ? "fuelUnit"
                                      : getDropDownOptions(key, "volumeUnit")?.length
                                      ? "volumeUnit"
                                      : getDropDownOptions(key, "energyUnit")?.length
                                      ? "energyUnit"
                                      : getDropDownOptions(key, "distanceUnit")?.length
                                      ? "distanceUnit"
                                      : getDropDownOptions(key, "weightUnit")?.length
                                      ? "weightUnit"
                                      : "quantityUnit"
                                  }
                                  size="large"
                                  filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                  }
                                  className={`${
                                    !facData.year || !facData.facility
                                      ? "disabled text-start"
                                      : "modalInputTable text-start"
                                  }`}
                                  onChange={
                                    getDropDownOptions(key, "fuelUnit")?.length
                                      ? (value) => rowDropdownChange("fuelUnit", value, key)
                                      : getDropDownOptions(key, "volumeUnit")?.length
                                      ? (value) => rowDropdownChange("volumeUnit", value, key)
                                      : getDropDownOptions(key, "energyUnit")?.length
                                      ? (value) => rowDropdownChange("energyUnit", value, key)
                                      : getDropDownOptions(key, "distanceUnit")?.length
                                      ? (value) => rowDropdownChange("distanceUnit", value, key)
                                      : getDropDownOptions(key, "weightUnit")?.length
                                      ? (value) => rowDropdownChange("weightUnit", value, key)
                                      : (value) => rowDropdownChange("quantityUnit", value, key)
                                  }
                                  value={
                                    getDropDownOptions(key, "fuelUnit")?.length
                                      ? row["fuelUnit"]
                                      : getDropDownOptions(key, "volumeUnit")?.length
                                      ? row["volumeUnit"]
                                      : getDropDownOptions(key, "energyUnit")?.length
                                      ? row["energyUnit"]
                                      : getDropDownOptions(key, "distanceUnit")?.length
                                      ? row["distanceUnit"]
                                      : getDropDownOptions(key, "weightUnit")?.length
                                      ? row["weightUnit"]
                                      : row["quantityUnit"]
                                  }
                                  options={
                                    getDropDownOptions(key, "fuelUnit")?.length
                                      ? getDropDownOptions(key, "fuelUnit")
                                      : getDropDownOptions(key, "volumeUnit")?.length
                                      ? getDropDownOptions(key, "volumeUnit")
                                      : getDropDownOptions(key, "energyUnit")?.length
                                      ? getDropDownOptions(key, "energyUnit")
                                      : getDropDownOptions(key, "distanceUnit")?.length
                                      ? getDropDownOptions(key, "distanceUnit")
                                      : getDropDownOptions(key, "weightUnit")?.length
                                      ? getDropDownOptions(key, "weightUnit")
                                      : getDropDownOptions(key, "quantityUnit")
                                  }
                                  disabled={
                                    !facData.year ||
                                    !facData.facility ||
                                    (subItem?.dependentHeaders?.length &&
                                      (!getDropDownOptions(key, "fuelUnit")?.length ||
                                        !getDropDownOptions(key, "energyUnit")?.length ||
                                        !getDropDownOptions(key, "volumeUnit")?.length ||
                                        !getDropDownOptions(key, "weightUnit")?.length ||
                                        !getDropDownOptions(key, "distanceUnit")?.length ||
                                        !getDropDownOptions(key, "quantityUnit")?.length
                                      )
                                    )
                                  }
                                  getPopupContainer={getPopupContainer}
                                />
                              }
                              defaultValue=""
                            />
                          ) : subItem.label === "Amount" || subItem.label === "Cost" ? (
                            <Input
                              className="hybrid-input"
                              name={subItem.fieldName}
                              value={row[subItem.fieldName]}
                              onChange={(e) => handleRowChange(subItem.fieldName, e.target.value, key)}
                              addonAfter={
                                <Select
                                  showSearch
                                  style={{ height: "40px", width: "115px" }}
                                  name={"currency"}
                                  size="large"
                                  filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                  }
                                  className={`${
                                    !facData.year || !facData.facility
                                      ? "disabled text-start"
                                      : "modalInputTable text-start"
                                  }`}
                                  // placeholder={`Select ${subItem.label}`}
                                  onChange={(value) => rowDropdownChange("currency", value, key)}
                                  value={row["currency"]}
                                  options={getDropDownOptions(key, "currency")}
                                  disabled={
                                    !facData.year ||
                                    !facData.facility ||
                                    (subItem?.dependentHeaders?.length && !getDropDownOptions(key, "currency").length)
                                  }
                                  getPopupContainer={getPopupContainer}
                                />
                              }
                              defaultValue=""
                            />
                          ) : (
                            <input
                              type={subItem.dataType === "string" ? "text" : "number"}
                              min="0"
                              className={`form-control ${
                                !facData.year || !facData.facility
                                  ? "disabled text-start"
                                  : "modalInputTable text-start"
                              }`}
                              placeholder={`Enter ${subItem.label}`}
                              name={subItem.fieldName}
                              value={row[subItem.fieldName]}
                              onChange={(e) => handleRowChange(subItem.fieldName, e.target.value, key)}
                              disabled={!facData.year || !facData.facility}
                              // oninput="validity.valid||(value='');"
                            />
                          )}
                        </>
                      }
                    </TableCell>
                  )
                )
              )}

              {name !== "Air Emission" && (
                <TableCell
                  className={`stickyTableCell2 calculateCell ${
                    !tabWiseValueGlobal[receivedDataId].isMandatoryFilled[key] ? "cellDisabled" : ""
                  }`}
                  component="th"
                  scope="row"
                >
                  {tabWiseValueGlobal[receivedDataId].rowDataArr[key]?.totalEmission ||
                  tabWiseValueGlobal[receivedDataId].rowDataArr[key]?.totalEmission === 0 ? (
                    <button className="btn btn-outline-info" disabled={true}>
                      {tabWiseValueGlobal[receivedDataId].rowDataArr[key]?.totalEmission}
                    </button>
                  ) : (
                    <>{isLoading[key] ? <ButtonLoader /> : <></>}</>
                  )}
                </TableCell>
              )}

              <TableCell className="stickyTableCell" component="th" scope="row">
                {key !== 0 && (
                  <img
                    src="/images/icons/delete-icon.svg"
                    alt=""
                    onClick={() => deleteRow(key)}
                    className="cursor-pointer"
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell className="text-start stickyTableCellLeft">
              <button type="button" className="btn btn-outline-success m-0 text-start addRowStyle" onClick={addNewRow}>
                + Add Row
              </button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(DataTable);
