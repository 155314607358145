import { useSelector } from "react-redux";
import "./ReportStatus.scss";

const ReportStatus = () => {
  const reportCreationData = useSelector((state) => state.reportVariable?.reportCreationData);

  if (!reportCreationData) {
    return <p></p>;
  }

  const { creationSource, baseReportYear } = reportCreationData;

  const getMessage = () => {
    if (creationSource === "xbrlUpload") {
      return `Data pre-filled from the FY ${baseReportYear} XBRL file`;
    } else if (creationSource === "previousReport") {
      return `Data copied from the FY ${baseReportYear} report created on this platform`;
    } else {
      return "";
    }
  };

  const message = getMessage();

  return (
    <div className="d-flex gap-1 align-items-center">
      {message && (
        <>
          <p className="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <circle cx="9" cy="6" r="0.75" fill="#1F93EF" />
              <path
                d="M9 9V12M15.75 9C15.75 12.7279 12.7279 15.75 9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9Z"
                stroke="#1F93EF"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </p>
          <p className="status-message">{message}</p>
        </>
      )}
    </div>
  );
};

export default ReportStatus;
