import React, { useEffect, useState } from "react";
import "./TeamMembers.scss";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Space, Checkbox } from "antd";
import { capitalizeFirstLetter } from "../../../../helper/Utils/utilitiyFunctions";
import CustomModal from "../../../../components/Modal/Modal";
import { usePutUpdateRoleMutation } from "../../../../state/api";
import { useSnackbar } from "../../../../components/snackbar/SnackbarContext";
// import { Checkbox } from "@mui/material";
const icons = {
  maker: "/images/BRSR/maker-icon.svg",
  // Reviewer: "/images/BRSR/reviewer-icon.svg",
  // Approver: "/images/BRSR/approver-icon.svg",
  "reviewer/approver": "/images/BRSR/reviewer-approver.svg",
  // "remove role": ""
};
export const TeamMembers = ({
  data,
  facilityId,
  handleCheck,
  activeButton,
  activeAssingButton,
  handleCheckForBulk,
  setRefreshFacility,
  refreshFacility,

}) => {
  const { openSnackbar } = useSnackbar();
  const [putUpdateRole, resultUpdateRole] = usePutUpdateRoleMutation();

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [updateRoleModal, setUpdateRoleModal] = useState(false);
  const [selectUpdateRole, setSelectUpdateRole] = useState("")

  useEffect(() => {
    if (data.role) {
      setSelectedValue(capitalizeFirstLetter(data.role));
      setSelectedIcon(icons[data.role]);
    } else {
      setSelectedValue("Select role");
      setSelectedIcon("");
    }
  }, [data.role]);

  const onCheckChange = (role) => {
    const contributorFacilityId = facilityId === "ALL" ? data.facilityId : "";
    handleCheck(data._id, contributorFacilityId, role);
  };

  const handleMenuClick = (e) => {
    const key = e.key;
    if (selectedValue !== "Select role" && data.isRole) {
      setUpdateRoleModal(true)
      setSelectUpdateRole(key)
      return
    }
    else if (key === "remove role") {
      setSelectedValue("Select role");
      setSelectedIcon("");
    } else {
      setSelectedValue(key);
      setSelectedIcon(icons[key] || "");

    }
    onCheckChange(key);

  };

  const handleCheckInBulk = () => {
    const contributorFacilityId = facilityId === "ALL" ? data.facilityId : "";
    handleCheckForBulk(data._id, contributorFacilityId);
  };

  const handleUpdateInModal = (role) => {
    // console.log("role" ,role)
    // setUpdateRoleModal(true)
  }

  const updateRole = async () => {
    const contributorFacilityId = facilityId === "ALL" ? data.facilityId : facilityId;
    const body = {
      facility: contributorFacilityId,
      contributor: data._id,
      role: selectUpdateRole
    };

    try {
      const response = await putUpdateRole(body);
      if (response?.data?.success) {
        openSnackbar(response?.data?.message, "success");
        setSelectedValue(selectUpdateRole);
        setUpdateRoleModal(false)
        setSelectUpdateRole("")
        setRefreshFacility(!refreshFacility)
      } else {
        openSnackbar(response?.error?.data?.message, "warning", 5000);
        // setUpdateRoleModal(false)
      }
    } catch (error) {
      console.log(error);
    }

  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {Object.keys(icons).map((key) => (
        <Menu.Item
          key={key}
          icon={key === "remove role" ? null : <img src={icons[key]} alt={`${key} icon`} className="menu-item-icon" />}
        >
          {capitalizeFirstLetter(key)}
        </Menu.Item>
      ))}
    </Menu>
  );
  const departmentList = data.department.length > 0 ? data.department.join(", ") : "";

  return (
    <>
      <CustomModal modalOpen={updateRoleModal} setModalOpen={setUpdateRoleModal} outSideClose={false}>
        <div className="d-flex gap-2">
          <div>
            <img src="/images/BRSR/color-info-icon.svg" alt="" />
          </div>
          <div style={{ maxWidth: "390px" }}>
            <div>
              <p className="modal-title-plan text-start" style={{ fontSize: "16px" }}>
                Are you sure you want to change the role of this contributor?
              </p>
              <p className="sub-heading-text" style={{ fontSize: "14px" }}>
                This user has been assigned to multiple facilities, If you change the role from here then the role of
                this user will change in all facilities.
              </p>
            </div>

            <div className="d-flex gap-4 mt-3">
              <button type="button" className="btn btn-outline-success " onClick={() => setUpdateRoleModal(false)}>
                Cancel
              </button>
              <button type="button" className="btn btn-success" onClick={updateRole}>
                Change role
              </button>
            </div>
          </div>
        </div>
      </CustomModal>

      <div className={`team-wrapper ${data.isTeamMember ?  data.isChecked ? "selected-member-green" : "selected-member" : ""}`}>
        <div className="user-info d-flex justify-content-center gap-2">
          <div>
            {activeAssingButton === "bulk" && facilityId === "ALL" && selectedValue === "Select role" && (
              <Checkbox
                style={{ marginRight: "10px" }}
                checked={JSON.parse(data.isChecked)}
                onChange={handleCheckInBulk}
              />
            )}
            <Avatar size={38} icon={<UserOutlined />} />
          </div>
          <div className="user-details">
            <p className="user-name">{data.name}</p>
            <p className="user-email">{data.email}</p>
            {facilityId === 'ALL' &&
              <p className="user-email">{data.facilityName}</p>
            }

            <>
              <div className="user-email">
                DSG: {data.designation}
              </div>
              <div className="user-email">
                DPT: {departmentList && departmentList}
              </div>
            </>
          </div>
        </div>
        {activeButton === "assign" ? (
          <div className="assign-role">
            {activeAssingButton === "bulk" && facilityId === "ALL" ? (
              <p className="select-text">{capitalizeFirstLetter(data.role)}</p>
            ) : (
              <Dropdown overlay={menu} trigger={["click"]} onClick={() => handleUpdateInModal(data.role)}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    {selectedIcon ? <img src={selectedIcon} alt="Selected icon" className="menu-trigger-icon" /> : ""}
                    <p className="select-text">{capitalizeFirstLetter(selectedValue)}</p>
                    <img src="/images/icons/select-down-arraow.svg" className="cursor-pointer" alt="icon" onClick={() => handleUpdateInModal(data.role)} />
                  </Space>
                </a>
              </Dropdown>
            )}
          </div>
        ) : (
          <div className="check-role">
            {data.isTeamMember && data.isChecked ? (
              <img
                src="/images/BRSR/checked-icon.svg"
                className="cursor-pointer"
                width={28}
                onClick={onCheckChange}
                alt="icon"
              />
            ) : (
              <Checkbox
                defaultChecked
                sx={{
                  color: "#16161E52",
                  padding: "0px",
                  "&.Mui-checked": {
                    color: "#64B5F6"
                  },
                  "& .MuiSvgIcon-root": { fontSize: 28, borderRadius: 20 }
                }}
                checked={data.isTeamMember}
                onChange={onCheckChange}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
